import React, { useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function EditableTextBox({ initialContent, isAdmin, onSave }) {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(initialContent);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleSaveClick = () => {
    onSave(content);
    setIsEditing(false);
  };

  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        overflow: "auto",
        margin: "1rem",
        display: "flex",
        justifyContent: "center", // Centers the content horizontally
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px", // Limits the content width for spacing on the sides
          margin: "0 auto", // Centers the inner Box
        }}
      >
        {!isEditing && isAdmin && (
          <IconButton
            onClick={handleEditClick}
            sx={{ position: 'absolute', top: 8, right: 8, color: "black" }}
          >
            <EditIcon />
          </IconButton>
        )}
        {isEditing ? (
          <>
            <ReactQuill
              value={content}
              onChange={handleContentChange}
              theme="snow"
              modules={modules}
              style={{
                backgroundColor: "#1c1f28",
                color: "white",
                width: "100%",
                minHeight: "300px", // Set an appropriate height
                maxWidth: "800px", // Ensure the same maxWidth as the content
                margin: "0 auto", // Center the editor
              }}
            />
            <Button
              onClick={handleSaveClick}
              sx={{
                marginTop: 2,
                backgroundColor: "var(--accent-color)",
                color: "white",
                "&:hover": {
                  backgroundColor: "#58595b",
                },
              }}
            >
              Save
            </Button>
          </>
        ) : (
          <Typography
            className="text"
            sx={{
              color: "black",
              whiteSpace: "pre-line",
              width: "100%",
              maxWidth: "800px",
              margin: "0 auto",
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </Box>
    </Box>
  );
}
