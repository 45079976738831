// Login.js

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import {
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function Login() {
  const navigate = useNavigate();
  const [userAuth, setUserAuth] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setUserAuth({
      ...userAuth,
      [event.target.name]: event.target.value,
    });
  };

  async function authorizeUser(email, password) {
    try {
      const response = await api.post("/api/auth/login", {
        email: email,
        password: password,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async function handleLoginAccount(e, user) {
    e.preventDefault(); // Prevent the default form behavior
    try {
      // Authenticate user
      const cognitoResp = await authorizeUser(user.email, user.password);
      if (cognitoResp && cognitoResp.status === 202) {
        const accessToken = cognitoResp.data.AuthenticationResult.AccessToken;
        const refreshToken = cognitoResp.data.AuthenticationResult.RefreshToken;
        const idToken = cognitoResp.data.AuthenticationResult.IdToken;

        // Store tokens in local storage
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("idToken", idToken);

        navigate("/dashboard");

        // Reset userData
        setUserAuth({
          email: "",
          password: "",
        });
      } else if (cognitoResp && cognitoResp.status === 401) {
        if (cognitoResp.data.code === "NotAuthorizedException") {
          alert(cognitoResp.data.message);
        } else {
          console.error("Failed to authenticate user");
        }
      } else {
        console.error("Unexpected response:", cognitoResp);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }

  return (
    <Box
      bgcolor="var(--primary-color)"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      <img
        src={process.env.PUBLIC_URL + "/images/Oval.png"}
        alt="Oval"
        style={{
          width: "50px",
          height: "50px",
          position: "absolute",
          top: "50px",
          left: "50px",
        }}
      />

      <Typography
        className="text"
        style={{
          position: "absolute",
          top: "20%",
          left: "50px",
          width: "500px",
          fontSize: "25px",
        }}
      >
        Continue your journey of growth, learning, and adaptation.
      </Typography>

      <Box
        border="3px solid var(--accent-color)"
        padding="5rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="50%"
        left="40%"
        position="absolute"
        top="20%"
        borderRadius="50px"
        maxWidth="500px"
      >
        <form onSubmit={(e) => handleLoginAccount(e, userAuth)}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className="header" style={{ marginBottom: "16px" }}>
                Sign In
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <TextField
                id="email"
                name="email"
                label="Email Address"
                variant="standard"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{
                  className: "text",
                  style: { color: "var(--body-text-color" },
                }}
                InputProps={{
                  className: "text",
                  style: { color: "var(--body-text-color)" }, // Apply the same color to the input text
                }}
                sx={{
                  borderBottom: "1px solid var(--body-text-color)",
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "48px" }}>
              <FormControl
                fullWidth
                variant="standard"
                style={{ borderBottom: "1px solid var(--body-text-color)" }}
              >
                <InputLabel
                  htmlFor="standard-adornment-password"
                  style={{ color: "var(--body-text-color)" }}
                  className="text"
                >
                  Password
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        sx={{
                          color: "var(--body-text-color)",
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    color: "var(--body-text-color)",
                  }}
                  className="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "48px" }}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{ height: "48px", backgroundColor: "#602EA0" }}
                className="text"
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={12} className="text">
                <Typography className="text" align="left">
                  Don't have an account?{" "}
                  <Link to="/signup" style={{ color: "var(--accent-color)" }}>
                    Sign up
                  </Link>
                  {""}
                  <br/>
                  <Link to="/forgot-password" style={{ color: "var(--accent-color)" }}> 
                    Forgot Your Password? 
                  </Link>
                </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}
