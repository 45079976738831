// ReplyPost.js
import React from "react";
import { Box, Typography } from "@mui/material";

const ReplyPost = ({ reply }) => {
  return (
    <Box sx={{ backgroundColor: "#e0e0e0", borderRadius: "10px", padding: "10px", marginBottom: "10px", marginLeft: "20px" }}>
      <Typography variant="body2" color="black">{reply.content}</Typography>
      <Typography variant="caption" color="textSecondary">
        Posted by {reply.author} on {new Date(reply.date).toLocaleDateString()}
      </Typography>
    </Box>
  );
};

export default ReplyPost;
