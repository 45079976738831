import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  // FormControl,
  // Select,
  // MenuItem,
  InputBase,
  IconButton,
  Typography,
} from "@mui/material";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export default function SearchPost({ updateSearchParams, page, updateFlag, setForumComp }) {
  const [searchParams, setSearchParams] = useState({
    title: "",
    content: "",
    tag: page.toLowerCase() || "",
  });
  const [includeArchived, setIncludeArchived] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClear = () => {
    setSearchParams((prevState) => ({
      ...prevState,
      title: "",
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateSearchParams(searchParams);
    updateFlag(true); // Set flag to use searchParams instead of default
  };

  const createNewPost = () => {
    setForumComp("form-state");
  };

  useEffect(() => {
    if (searchParams.tag) {
      // Fetch posts whenever the tag changes
      updateFlag(true);
    }
  }, [searchParams.tag, updateFlag, updateSearchParams]);

  const imageHeight = 50;
  const imageWidth = imageHeight + 10;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#1c1f28",
        borderRadius: "20px",
        padding: "25px",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <img
        src={process.env.PUBLIC_URL + "/images/prism.jpg"}
        alt="Logo"
        style={{
          width: `${imageWidth}px`,
          height: `${imageWidth}px`,
          borderRadius: "50%",
          marginRight: "15px",
          display: "flex",
          alignItems: "center",
        }}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#1c1f28",
          borderRadius: "5px",
          padding: "0 10px",
          border: "1px solid white",
          flex: 2,
          height: `${imageHeight}px`,
        }}
      >
        <SearchIcon sx={{ color: "white", marginRight: "10px" }} />
        <InputBase
          placeholder="Search Post"
          name="title"
          value={searchParams.title}
          onChange={handleChange}
          sx={{
            color: "white",
            flex: 1,
            height: "100%", // Ensure the input height matches the container
          }}
        />
        {searchParams.title && (
          <IconButton onClick={handleClear} size="small">
            <ClearIcon sx={{ color: "white" }} />
          </IconButton>
        )}
      </Box>

      {/* <FormControl
        sx={{
          backgroundColor: "#1c1f28",
          borderRadius: "5px",
          border: "1px solid white",
          flex: 1,
          height: `${imageHeight}px`,
        }}
      >
        <Select
          value={searchParams.tag}
          onChange={handleChange}
          name="tag"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ color: "white", height: "100%" }} // Ensure select height matches other elements
          IconComponent={ArrowDropDownIcon}
        >
          <MenuItem value={""}>None</MenuItem>
          <MenuItem value={"real estate"}>Real Estate</MenuItem>
          <MenuItem value={"politics"}>Politics</MenuItem>
          <MenuItem value={"education"}>Education</MenuItem>
        </Select>
      </FormControl> */}
      
      <Button
        type="button"
        variant="contained"
        sx={{
          backgroundColor: includeArchived ? "var(--accent-color)" : "#404149",
          color: "white",
          borderRadius: "5px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#58595b",
          },
          height: `${imageHeight}px`, // Set the height to match other elements
          width: "150px", // Adjust width to be proportional
        }}
        onClick={() => setIncludeArchived(!includeArchived)}
      >
        Include Archived
      </Button>
      <Button
        sx={{
          padding: "8px",
          textTransform: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#28a745",
          color: "white",
          "&:hover": {
            backgroundColor: "#218838",
          },
          borderRadius: "5px",
          height: `${imageHeight}px`, // Set the height to match other elements
          width: "150px",
        }}
        onClick={createNewPost}
      >
        <Typography sx={{ fontWeight: "bold" }}>Create Post</Typography>
      </Button>
      <Button
        type="submit"
        variant="contained"
        sx={{
          display: 'none'
        }}
      >
        Search
      </Button>
    </Box>
  );
}
