import { Box, Typography, Container, Button } from '@mui/material';
import { useState } from 'react';
import FeedbackForm from '../FeedbackForm';


export default function Innovator({name, handleRetakeAssessment}){
    const [showFeedbackForm, setShowFeedbackForm] = useState(false); 

    return (
        <Box>
            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', mr: 1 }}>
                        Discover Your Path to Growth
                    </Typography>
                    <img
                        src={process.env.PUBLIC_URL + '/images/growth.png'}
                        alt="Grow"
                        style={{
                            height: "8%", 
                            width: "8%",
                        }}
                    />
                </Box>
                
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Dear {name},

                    <br/><br/>
                    Congratulations 🎉 on completing the Survival Instinct Awareness Assessment! Your
                    results have revealed invaluable insights into how your survival instincts shape your
                    thoughts and actions. This awareness is the first step toward unlocking a more open
                    and growth-oriented mindset.

                    <br/><br/>
                    
                    <strong>Your Personalized Insights:</strong> Based on your responses, you are a <strong>Innovator 💡</strong>. This suggests 
                    that your survival instincts are low, and you have a high openness to change.
                </Typography>

                <Box sx={{display:'flex', justifyContent: 'space-evenly'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold' }}>Want To Retake The Assessment</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRetakeAssessment}
                            sx={{background: 'var(--accent-color)',
                                '&:hover': {
                                    backgroundColor: '#a962e3', // Color on hover
                                },}}
                        >
                            Retake Assessment
                        </Button>
                    </Box>
                    
                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold' }}>Want To Leave Direct Feedback</Typography>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => setShowFeedbackForm(true)}
                        >
                            Leave Direct Feedback
                        </Button>
                    </Box>
                </Box>
            </Container>

            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>
                <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2 }}>
                    Archetype: The Innovator
                </Typography>


                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <img
                    src={process.env.PUBLIC_URL + '/images/innovator.png'}
                    alt="innovator"
                    style={{
                        height: "80%",
                        width: "45%",
                    }}
                    />

                    <Box sx={{ ml: 3, mt: 5 }}>
                        <Typography variant="body1" sx={{fontSize: '1.1rem'}}>
                            <strong>Key Traits:</strong> Free & Present, Purposeful, Determined, Fearless, Risk-taker
                        </Typography>

                        <Typography variant="body1" sx={{fontSize: '1.1rem', mt: 3 }}>
                            <strong>Your Journey:</strong> You’ve cultivated an open mind and learned from mistakes and
                            different perspectives. Your high emotional intelligence allows you to navigate
                            challenges without being held back by negative reactions. You thrive on learning,
                            collaboration, and the excitement of new experiences. Embrace a life of
                            continuous growth and innovation, taking on new challenges with optimism.
                        </Typography>
                    </Box>

                </Box>

                <Typography variant="body1" sx={{ mb: 2, mt: 4, fontSize: '1.1rem'}}>
                    <strong>Why This Matters: </strong> <br/>
                    You already possess a remarkable openness to change, but there is always room for
                    further growth and refinement. By continuing to explore the depths of your instincts, you
                    can achieve even greater alignment with your purpose and potential. Your journey
                    doesn't end with awareness; it evolves as you use this awareness to innovate, create,
                    and lead. Imagine the transformative impact when you help others to break free from
                    their fears and resistance!

                    <br/><br/>
                    <strong>Keep Exploring: </strong> <br/>
                    Your role as an Innovator is not just to grow yourself but to be a catalyst for others&#39;
                    growth. Dive deeper into resources, explore uncharted areas of your instincts, and
                    engage with our community to foster a culture of co-creation and inspiration. You are in
                    a unique position to shape not only your future but the future of others who look up to
                    you as a guide.

                    <br/><br/>
                    <strong>Intrigued?</strong> <br/>
                    Start a conversation on the SI platform today and share your insights with others.
                    Become a mentor, spark new dialogues, and contribute to a community dedicated to
                    collective growth and innovation. Your journey towards an even more expansive and
                    impactful life is just a few steps away.
                </Typography>
            </Container>

            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>

                <Typography variant="body1" sx={{ mb: 2, fontSize: '1.1rem'}}>
                    <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2 }}>
                        Next Steps on Your Journey
                    </Typography>

                    <strong>1. Embrace New Prespectives: </strong> <br/>
                    Delve into Theory U by Otto Scharmer. This transformative approach aligns with
                    the Innovator archetype by encouraging deep listening, profound awareness, and
                    co-creating emerging futures. Theory U resonates with your openness and desire
                    to explore new frontiers, guiding you to harness your creativity to shape
                    innovative solutions for the future.

                    <br/><br/>
                    <strong>2. Join the SI Community: </strong> <br/>
                    Engage with others who share similar aspirations. As an Innovator, you can play
                    a pivotal role in sparking dialogue and guiding others on their path to openness
                    and awareness.

                    <br/><br/>
                    <strong>3. Co-create for Positive Impact:</strong><br/>
                    Use your high awareness and openness to inspire others. Create content, share
                    insights, or help answer questions within the SI community. Your journey can be
                    a beacon for those looking to embrace change.

                    <br/><br/>
                    <strong>4. Activate Your Mentor Powers:</strong><br/>
                    Reflect on your journey to self-awareness and consider mentoring others. Your
                    story can be both helpful and inspiring, offering others a roadmap to openness
                    and growth.

                    <br/><br/>
                    Warm regards, <br/>
                    <strong>The Synergistic Intelligence Team</strong>
                </Typography>
            </Container>

            <FeedbackForm open={showFeedbackForm} onClose={() => setShowFeedbackForm(false)} />
        </Box>
    );
}