import React, { useState } from 'react';
import api from "../../api";
import { Modal, Box, Button, TextField, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FeedbackForm = ({ open, onClose, title }) => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message) {
      setError(true);
      return;
    }

    try {
      const response = await api.post('/api/email/send', { name, message });

      if (response.status === 200) {
        setSuccess(true);
        setName('');
        setMessage('');
        setError(false);
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
      setError(true);
      setSuccess(false);
    }
  };

  const renderFormContent = () => (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name (optional)"
        variant="outlined"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ marginBottom: 2, backgroundColor: 'white', borderRadius: '10px' }}
      />
      <TextField
        label="Message"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{ marginBottom: 2, backgroundColor: 'white', borderRadius: '10px' }}
        error={error && !message}
        helperText={error && !message ? "Message content is required" : ""}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </form>
  );

  const renderSuccessContent = () => (
    <Box sx={{ textAlign: 'center', color: 'black' }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Thank you for your feedback. Your message has been sent successfully.
      </Typography>
      <Button variant="contained" color="primary" onClick={onClose}>
        Close
      </Button>
    </Box>
  );

  const renderErrorContent = () => (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        There was an error sending your feedback. Please try again later.
      </Typography>
      <Button variant="contained" color="primary" onClick={onClose}>
        Close
      </Button>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="feedback-form-title"
      aria-describedby="feedback-form-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box sx={{ position: 'relative' }}>
          {!success && !error && (
            <IconButton
              onClick={onClose}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography
            id="feedback-form-title"
            variant="h4"
            sx={{ mb: 2, fontWeight: 'bold', color: 'black', textAlign: 'center' }}
          >
            {success ? 'Feedback Submitted' : error ? 'Error Occurred' : title || 'Feedback Form'}
          </Typography>
          {success ? renderSuccessContent() : error ? renderErrorContent() : renderFormContent()}
        </Box>
      </Box>
    </Modal>
  );
};

export default FeedbackForm;
