import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Paper, Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import "../styles/styles.css";
import "../styles/tokens.css";
import api from '../api';

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await api.get('/api/blogs');
                const data = response.data.blogs; 
                setBlogs(data);
            } catch (error) {
                console.error("Error fetching blogs:", error);
            }
        };
        fetchBlogs();
    }, []);

    const handleCreateBlog = () => {
        navigate('/create-blog'); // Navigate to the create blog page
    };

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    return (
        <>
            <Box sx={{ paddingTop: '5rem', backgroundColor: '#000000', minHeight: '100vh', paddingBottom: '2.5rem', color: 'white' }}>
                <Container sx={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                    <Box sx={{ width: '100%', marginBottom: '2.5rem', textAlign: 'left' }}>
                        <Typography variant="h2" component="h1" sx={{ color: '#FFFFFF', fontWeight: 'bold', marginBottom: '0.625rem' }}>
                            Synergistic Intelligence Blogs
                        </Typography>
                        {/* <Box sx={{ width: '3.75rem', height: '0.25rem', backgroundColor: 'var(--accent-color)', marginBottom: '1.25rem' }}></Box>

                        <Typography variant="h6" component="p" sx={{ fontWeight: "bold", fontSize: "2rem", color: 'var(--accent-color)' }}>
                            What topics does Synergistic Intelligence cover in its blog?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Synergistic Intelligence covers a wide range of topics related to artificial intelligence, theory-U topics, and more.
                        </Typography>
                        <Typography variant="h6" component="p" sx={{ fontWeight: "bold", fontSize: "2rem", color: 'var(--accent-color)' }}>
                            How does Synergistic Intelligence use Theory U concepts in its teaching?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Synergistic Intelligence incorporates Theory U concepts to help readers explore new perspectives, challenge assumptions, and foster creative thinking in the field of artificial intelligence.
                        </Typography>
                        <Typography variant="h6" component="p" sx={{ fontWeight: "bold", fontSize: "2rem", color: 'var(--accent-color)' }}>
                            How does Synergistic Intelligence push the boundaries of AI technology?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Synergistic Intelligence is committed to exploring cutting-edge technologies, conducting research, and collaborating with industry leaders to drive innovation and advance the field of artificial intelligence.
                        </Typography>
                        <Typography variant="h6" component="p" sx={{ fontWeight: "bold", fontSize: "2rem", color: 'var(--accent-color)' }}>
                            How can I become part of the Synergistic Intelligence community?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You can join the Synergistic Intelligence community by subscribing to our blog, attending our events, participating in discussions, and sharing your ideas and insights with like-minded individuals.
                        </Typography> */}
                    </Box>

                    <Box sx={{ width: '100%', textAlign: 'left', display: 'flex'}}>
                        <Typography variant="h2" component="p" sx={{ fontWeight: "bold", fontSize: "2rem", color: 'var(--accent-color)' }}>
                            Blog Post
                        </Typography>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleCreateBlog}
                            sx={{ marginBottom: '1.5rem', marginLeft: '4rem' }}
                        >
                            Create Blog
                        </Button>
                    </Box>

                    <Grid container spacing={4} sx={{ width: '100%' }}>
                        {blogs.length > 0 ? blogs.map((post) => (
                            <Grid item key={post._id} xs={12} sm={6} md={4}>
                                <Link to={`/blog/${post._id}`} style={{ textDecoration: 'none' }}>
                                    <Paper elevation={3} sx={{ padding: '1.875rem', borderRadius: '0.625rem', backgroundColor: '#FFFFFF', color: 'black', height: '21.875rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', transition: 'transform 0.3s, box-shadow 0.3s', '&:hover': { transform: 'translateY(-0.625rem)', boxShadow: '0 0.375rem 1.125rem rgba(0,0,0,0.1)' } }}>
                                        <Box>
                                            <img src={post.image} alt={post.title} style={{ width: '100%', height: '8.75rem', objectFit: 'cover', borderRadius: '0.625rem' }} />
                                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: '0.625rem', color: 'var(--accent-color)' }}>
                                                {post.title}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '0.625rem' }}>
                                                {new Date(post.date).toLocaleDateString()}
                                            </Typography>
                                            <Typography variant="body1" paragraph sx={{ marginBottom: '1.25rem' }}>
                                                {stripHtmlTags(post.content.substring(0, 100))}... {/* Adjust as needed */}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body1" sx={{ color: 'var(--accent-color)', fontWeight: 'bold' }}>
                                                Read more
                                            </Typography>
                                        </Box>
                                    </Paper>
                                </Link>
                            </Grid>
                        )) : (
                            <Typography variant="body1" sx={{ color: 'white', marginTop: '2rem', marginLeft: '2rem' }}>No blog posts available.</Typography>
                        )}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default BlogPage;
