// CommentItem.js

import React, { useState, useEffect } from "react";
import api from "../../../api";
import { Box, Grid, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import "../../../styles/styles.css";
import "../../../styles/tokens.css";

export default function CommentItem({ user, comment, setCommentFlag }) {
  // Author
  const [userName, setUserName] = useState("");
  const [isLiked, setIsLiked] = useState(null);
  const [numLikes, setNumLikes] = useState(null);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await api.get(
          `/api/forum/author?id=${comment.author}`
        );
        if (response.status === 200) {
          setUserName(response.data.author);
        }
      } catch (error) {
        console.error("Error fetching user name:", error);
      }
    };

    const fetchComment = async () => {
      try {
        const response = await api.get(`/api/forum/comments/${comment._id}`);
        setNumLikes(response.data.comment.likes.length);
        setIsLiked(response.data.comment.likes.includes(user._id));
      } catch (error) {
        console.error("Error fetching comment:", error);
      }
    };

    fetchUserName();
    fetchComment();
  }, [comment.author, comment._id, user._id, isLiked]);

  // Add Like
  const handleLikeClick = async (event) => {
    event.stopPropagation();
    try {
      const response = await api.post(
        `/api/forum/comments/likes/${comment._id}`,
        {
          user_id: user._id,
        }
      );
      if (response.status === 201) {
        setIsLiked(true);
      } else if (response.status === 204) {
        setIsLiked(false);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  // Delete comment
  const handleDeleteComment = async (event) => {
    event.stopPropagation();
    try {
      const response = await api.delete(`/api/forum/comments/${comment._id}`);
      if (response.status === 200) {
        setCommentFlag(true);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  // Date
  const dateObject = new Date(comment.date);
  const formattedDate = dateObject.toLocaleDateString();
  const formattedTime = dateObject.toLocaleTimeString();

  return (
    <Box
      sx={{
        borderRadius: "10px",
        padding: "8px",
        backgroundColor: "#1c1f28",
        marginBottom: "20px",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
            <span className="title">Posted by {userName}</span>
            <span className="forum-text">
              Date: {formattedDate}, Time: {formattedTime}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className="sub-text">{comment.content}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className="forum-text"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              {isLiked ? (
                <FavoriteIcon
                  sx={{ marginRight: "5px" }}
                  onClick={handleLikeClick}
                />
              ) : (
                <FavoriteBorderIcon
                  sx={{ marginRight: "5px" }}
                  onClick={handleLikeClick}
                />
              )}
              {numLikes}
            </span>
            {user._id === comment.author && (
              <span style={{ marginLeft: "8px" }}>
                <DeleteOutlineIcon onClick={handleDeleteComment} />
              </span>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
