// CreateBlog.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button, Box, Snackbar, Alert } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/styles.css";
import "../../styles/tokens.css";
import api from "../../api";

const CreateBlog = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [content, setContent] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("date", date);
      formData.append("content", content);
      if (imageFile) {
        formData.append("image", imageFile);
      }

      await api.post("/api/blogs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setSnackbarMessage("Blog post created successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      setTimeout(() => {
        navigate("/blog");
      }, 2000);
    } catch (error) {
      console.error("Error creating blog post:", error);
      setSnackbarMessage("Error creating blog post. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Container sx={{ paddingTop: "5rem", backgroundColor: "#000000", color: "white", minHeight: "100vh", paddingBottom: "4rem" }}>
        <Typography variant="h2" component="h1" sx={{ color: "#AF69ED", fontWeight: "bold", marginBottom: "2rem" }}>
          Creating New Blog
        </Typography>
        <Box sx={{ marginBottom: "2rem" }}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{
                marginBottom: "1rem",
                backgroundColor: "black",
                color: "white",
                borderRadius: "0.625rem",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              required
              InputLabelProps={{
                style: { color: "white" },
              }}
              InputProps={{
                style: { color: "white" },
              }}
            />
            <TextField
              fullWidth
              label="Date"
              type="date"
              variant="outlined"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              sx={{
                marginBottom: "1rem",
                backgroundColor: "black",
                color: "white",
                borderRadius: "0.625rem",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              required
              InputLabelProps={{
                shrink: true,
                style: { color: "white" },
              }}
              InputProps={{
                style: { color: "white" },
              }}
            />
            <Button
              variant="contained"
              component="label"
              sx={{ marginBottom: "1rem", backgroundColor: "white", color: "black", borderRadius: "0.625rem" }}
            >
              Upload Image
              <input type="file" hidden onChange={handleImageChange} />
            </Button>
            <Box sx={{ marginBottom: "1rem", color: "white", backgroundColor: "black", borderRadius: "0.625rem", "& .ql-editor": { minHeight: "200px", color: "white" } }}>
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                style={{ height: "100%", marginBottom: "1rem", color: "white", backgroundColor: "black", borderRadius: "0.625rem" }}
              />
            </Box>
            <Button type="submit" variant="contained" color="primary" sx={{ marginBottom: "2rem" }}>
              Create Blog Post
            </Button>
          </form>
        </Box>
        <Box sx={{ textAlign: "left", marginBottom: "20px" }}>
          <Typography variant="h2" component="h1" sx={{ color: "#AF69ED", fontWeight: "bold", marginBottom: "20px" }}>
            {title || "Preview Title"}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: "20px" }}>
            {date}
          </Typography>
          {imagePreview && <img src={imagePreview} alt={title} style={{ width: "100%", height: "30rem", borderRadius: "10px", marginBottom: "20px" }} />}
          <Typography variant="body1" component="div" sx={{ whiteSpace: "pre-line" }}>
            <div dangerouslySetInnerHTML={{ __html: content || "Preview Content" }} />
          </Typography>
        </Box>
      </Container>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateBlog;
