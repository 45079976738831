import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function HTI() {
  return (
    <Box>
      <div style={{ position: "relative", height: "auto" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/Ellipse.png"}
          alt="Ellipse"
          style={{
            top: "-350px",
            left: "-10%",
            position: "absolute",
          }}
        />

        <Box
          p={2}
          sx={{
            position: "relative",
            top: "200px",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" className="title" style={{ fontSize: "48" }}>
            Holistic Transcendent Innovation
          </Typography>
        </Box>
        <Box
          p={2}
          sx={{
            position: "relative",
            top: "230px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "55%",
            textAlign: "center",
          }}
        >
          <Typography className="text" style={{ textAlign: "center" }}>
            In an era dominated by AI's transformative power, society faces a
            mix of fear, opportunity, and abundance. "Holistic Transcendent
            Innovation" (HTI) emerges as a process for individuals ready to
            embrace “flow” as the new view of the world and drive change, moving
            beyond old stagnant concepts.
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "600px", // Adjust based on your layout
            left: 0,
            width: "100%",
            height: "auto",
            backgroundColor: "white",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "top",
            padding: "80px 0px",
          }}
        >
          <Typography
            component="h2"
            className="pop"
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              marginLeft: "5%",
              maxWidth: "calc(100% - 70%)",
            }}
          >
            Holistic, Transcendent Innovation
          </Typography>

          {/* Paragraph of text */}
          <Typography
            className="text"
            sx={{
              width: "calc(100% - 55%)",
              color: "black",
              marginRight: "12%",
            }}
          >
            In the swell of our current era, we ride on the crest of
            exponentially increasing change, a formidable wave powered by the
            universal dissemination of Artificial Intelligence (AI). This wave,
            relentless and unforgiving, spares no shore of our lives from its
            transformative force. The landscape of employment, particularly the
            bastions of knowledge-based jobs, finds itself in the throes of
            disruption, leaving in its wake a mixture of fear, opportunity, and
            unprecedented abundance. This is not merely a period of
            technological advancement; it heralds a profound shift in the very
            fabric of our existence, challenging the inherited armors of
            self-protection and security that have shielded us thus far. These
            traditional bastions, once thought impregnable, now reveal
            themselves as sandcastles before the tide—wholly inadequate against
            the surging forces of change.
            <br />
            <br />
            It is within this context that a new paradigm of self emerges, a
            form that does not resist the tidal forces but instead learns to
            navigate its currents with agility and grace. This emergent self,
            imbued with a fluid identity, possesses capabilities that transcend
            our historical limitations, enabling not just adaptation but the
            flourishing of an abundancefilled world. Far from the static utopias
            of yesteryears, this world is dynamic, perpetually evolving and
            redefining itself through the process of Holistic Transcendent
            Innovation.
            <br />
            <br />
            Holistic Transcendent Innovation represents a nexus of ideas,
            drawing upon the foundational concepts from seminal works such as
            "The Fifth Discipline" by Peter Senge, which emphasizes the power of
            systemic thinking and the interconnectivity of our actions;
            "Presence" and "Theory U" by Otto Scharmer, which invite us to tap
            into our deepest sources of creativity and intuition to shape the
            future; "The Wisdom of Insecurity" by Alan Watts, which challenges
            us to find stability in the inherent instability of life; and "The
            Embodied Mind" by Francisco Varela, Evan Thompson, and Eleanor
            Rosch, which argues for a more integrated understanding of mind and
            body in shaping our perception of the world.
            <br />
            <br />
            This book ventures into the heart of Holistic Transcendent
            Innovation, a process that is not a privilege but a journey
            available to those rare individuals who are stepping into this new
            form of self-preservation—a self that is not rigid but malleable,
            capable of not just enduring but thriving amidst change. The world
            birthed through this process is not without its imperfections, nor
            does it aspire to be; it is the acknowledgment of these
            imperfections that forms the cornerstone of its success.
            Imperfection is not a flaw but a feature, a testament to the
            dynamic, ever-evolving nature of existence that this process seeks
            to embrace.
            <br />
            <br />
            If you find yourself on the cusp of this transformation, yearning to
            partake in the shaping of this new world, this book extends an
            invitation to join a movement of like- minded souls. Together, we
            embark on a journey not to a destination but towards a way of being,
            a process of becoming that continually unfolds. We are here not just
            to pass on knowledge but to share a gift—the gift of Holistic
            Transcendent Innovation. If you are ready to transcend the
            boundaries of the past and step into a future where change is not
            just anticipated but celebrated, we welcome you to join us in this
            transformative endeavor.
          </Typography>
        </Box>
      </div>
    </Box>
  );
}
