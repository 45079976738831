import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import "../../styles/styles.css";
import "../../styles/tokens.css";

const sections = [
  {
    title: "Understanding Stagnant Identity",
    recommendation: "Understanding Stagnant Identity",
    exploration: '"The Fifth Discipline" by Peter Senge to understand how to foster a learning organization and embrace change.',
    link: "/editable-page/SI/Understanding Stagnant Identity",
  },
  {
    title: "Exploring Survival Instincts",
    recommendation: "Evolution Our Survival Instinct",
    exploration: '“Presence” by Otto Scharmer, Peter Senge, Joseph Jaworski, and Betty Sue Flowers to understand how true, open collaboration can bring about benevolent, transformative change.',
    link: "/editable-page/SI/Exploring Survival Instincts",
  },
  {
    title: "Recognizing Ego Defensiveness",
    recommendation: "Self-Image Defense",
    exploration: 'Chapter 11 “The Fifth Discipline” Team Learning',
    link: "/editable-page/SI/Recognizing Ego Defensiveness",
  },
  {
    title: "Shifting Towards Flow Identity",
    recommendation: "Overcoming Stagnant Identity",
    exploration: '“Mindset” by Carol Dweck to explore how we grow with the understanding that we are malleable.',
    link: "/editable-page/SI/Shifting Towards Flow Identity",
  },
  {
    title: "Cultivating Openness and Awareness",
    recommendation: "Cultivating Openness and Awareness",
    exploration: '“The Wisdom of Insecurity” by Alan Watts to understand the importance of letting go and risk-taking.',
    link: "/editable-page/SI/Cultivating Openness and Awareness",
  },
  {
    title: "Deeper Personal Reflection",
    recommendation: "The Tao of Leadership",
    exploration: '“The Ten Thousand Things” by Robert Saltzman to understand what it means to be completely free to be yourself.',
    link: "/editable-page/SI/Deeper Personal Reflection",
  },
];

const AdditionalAssessment = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" sx={{ marginTop: 9, fontWeight: 'bold', marginBottom: 2 }}>
        Resource List for Personalized Recommendations
      </Typography>
      <Grid container spacing={2}>
        {sections.map((section, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Link to={section.link} style={{ textDecoration: 'none' }}>
              <Card
                raised
                sx={{
                  minHeight: 180,
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: 6,
                    cursor: 'pointer',
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{section.title}</Typography>
                  <Typography variant="subtitle1" sx={{ mt: 1 }}>{section.recommendation}</Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>{section.exploration}</Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdditionalAssessment;
