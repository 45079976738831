import React, { useState, useEffect } from "react";
import api from "../../api";
import { Box } from "@mui/material";
import PostItem from "./PostItem";
import SearchPost from "./SearchPost";

import "../../styles/styles.css";
import "../../styles/tokens.css";

export default function Feed({
  user,
  page = "default", // Default value for page
  setCurrPost,
  setForumComp,
  searchParams = {}, // Default value for searchParams
  useSearchParams = false, // Default value for useSearchParams
  updateFlag: parentUpdateFlag,
}) {
  const [posts, setPosts] = useState([]);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [searchParamsState, setSearchParamsState] = useState(searchParams);
  const [useSearchParamsFlag, setUseSearchParamsFlag] = useState(useSearchParams);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const query = useSearchParamsFlag
          ? new URLSearchParams(searchParamsState).toString() // Using search parameters if flag is true
          : `tag=${page.toLowerCase()}`; // Default behavior based on the page
        console.log("HELLO");
        console.log(query);
        const response = await api.get(`/api/forum?${query}`);
        if (response.status === 200) {
          const sortedPosts = response.data.posts.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setPosts(sortedPosts);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    if (deleteFlag) {
      setDeleteFlag(false);
    }

    fetchPosts();
  }, [page, deleteFlag, searchParamsState, useSearchParamsFlag, parentUpdateFlag]);

  const updateSearchParams = (params) => {
    setSearchParamsState(params);
    setUseSearchParamsFlag(true);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          gap: "10px",
        }}
      >
        <SearchPost updateSearchParams={updateSearchParams} page={page} updateFlag={parentUpdateFlag} setForumComp={setForumComp} />
      </Box>
      {posts.map((post) => (
        <PostItem
          key={post._id}
          user={user}
          post={post}
          setCurrPost={setCurrPost}
          setForumComp={setForumComp}
          currForumComp={"feed-state"}
          isClickable={true}
          commentFlag={null}
          setCommentFlag={null}
          setDeleteFlag={setDeleteFlag}
        />
      ))}
    </Box>
  );
}
