// Accountverification.js

import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, TextField, Grid, Button, Typography } from "@mui/material";
import "../styles/styles.css";
import "../styles/tokens.css";
import api from "../api";

export default function AccountVerification() {
  const navigate = useNavigate();
  const { email } = useParams();
  const digitRefs = useRef([]);
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  // Update the state with the new value at the specified index
  const handleChange = (event, index) => {
    const newValue = event.target.value;

    if (index < digitRefs.current.length - 1) {
      digitRefs.current[index + 1].focus();
    }

    setVerificationCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = newValue;
      return newCode;
    });
  };

  async function handleAccountVerification(e, verificationCode) {
    e.preventDefault(); // Prevent the default form behavior
    console.log(verificationCode.join(""));
    try {
      const cognitoResp = await api.post("/api/auth/verify", {
        email: email,
        verificationCode: verificationCode.join(""),
      });
      if (cognitoResp && cognitoResp.status === 200) {
        alert("Account created. Please login.");
        navigate("/login");
        setVerificationCode({
          verificationCode: "",
        });
      }
    } catch (error) {
      alert("Verification Code Failed.");
      return error.response;
    }
  }

  return (
    <Box
      bgcolor="var(--primary-color)"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      <img
        src={process.env.PUBLIC_URL + "/images/oval.png"}
        alt="Oval"
        style={{
          width: "50px",
          height: "50px",
          position: "absolute",
          top: "50px",
          left: "50px",
        }}
      />

      <Typography
        className="text"
        style={{
          position: "absolute",
          top: "20%",
          left: "50px",
          width: "500px",
          fontSize: "25px",
        }}
      >
        Finish setting up your account.
      </Typography>

      <Box
        border="3px solid var(--accent-color)"
        padding="5rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="50%"
        left="40%"
        position="absolute"
        top="20%"
        borderRadius="50px"
        maxWidth="500px"
      >
        <Box style={{ width: "500px" }}>
          <form
            onSubmit={(e) => handleAccountVerification(e, verificationCode)}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography className="header" style={{ marginBottom: "4px" }}>
                  Verify Email
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="sub-text"
                  style={{ marginBottom: "16px" }}
                >
                  A verification code has been sent to your email.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "24px" }}>
                <Grid container spacing={1}>
                  {[1, 2, 3, 4, 5, 6].map((index) => (
                    <Grid item xs={2} key={index}>
                      <TextField
                        id={`verification-code-${index}`}
                        name={`verification-code-${index}`}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          maxLength: 1, // Allow only one character input
                          style: {
                            textAlign: "center", // Center the text horizontally
                            fontSize: 24, // Adjust font size
                            color: "var(--body-text-color)",
                          },
                        }}
                        sx={{
                          border: "1px solid var(--body-text-color)",
                          borderRadius: "10px",
                        }}
                        inputRef={(el) => (digitRefs.current[index] = el)}
                        onChange={(e) => handleChange(e, index)} // Pass the index to the handleChange function
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "24px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{ height: "48px", backgroundColor: "#602EA0" }}
                >
                  Verify Email
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
