// DashTabBar.js

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function DashTabBar({ tab, setTab }) {
  return (
    <Box
      style={{
        width: "300px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        marginTop: "25px",
        borderBottom: "2px solid white",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ borderRight: "2px solid white" }}>
          <Button
            sx={{
              padding: 0,
              marginRight: "20px",
            }}
            onClick={() => setTab("resources-state")}
          >
            <Typography
              className="sub-text"
              sx={{
                textAlign: "left",
                fontWeight: tab === "resources-state" ? "bold" : "normal",
              }}
            >
              Resources
            </Typography>
          </Button>
        </div>
        <div style={{ borderRight: "2px solid white" }}>
          <Button
            sx={{ padding: 0, marginLeft: "10px", marginRight: "10px" }}
            onClick={() => setTab("chat-state")}
          >
            <Typography
              className="sub-text"
              sx={{
                textAlign: "left",
                fontWeight: tab === "chat-state" ? "bold" : "normal",
              }}
            >
              Chat
            </Typography>
          </Button>
        </div>
        <div style={{ marginRight: "0" }}>
          <Button
            sx={{ padding: 0, marginLeft: "20px" }}
            onClick={() => setTab("forum-state")}
          >
            <Typography
              className="sub-text"
              sx={{
                textAlign: "left",
                fontWeight: tab === "forum-state" ? "bold" : "normal",
              }}
            >
              Forum
            </Typography>
          </Button>
        </div>
      </Box>
    </Box>
  );
}
