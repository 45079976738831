// src/components/AssessmentSection.js
import React from 'react';
import { Box } from '@mui/material';
import Question from './Question';

const AssessmentSection = ({ section, answers, handleAnswer, currentSection, activeQuestionIndex }) => (
  <Box sx={{ marginBottom: 4 }}>
    {section.questions.map((q, qIndex) => (
      <Question
        key={qIndex}
        question={q.question}
        type={q.type}
        answer={answers[currentSection]?.[q.question]?.answer}
        onAnswer={(answer) => handleAnswer(currentSection, q.question, answer)}
        isActive={qIndex === activeQuestionIndex}
      />
    ))}
  </Box>
);

export default AssessmentSection;
