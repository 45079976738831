import React, { useState, useEffect } from "react";
import FullScreenVideo from "../components/FullScreenVideo";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function HomePage() {
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const handleVideoEnd = () => {
    setIsVideoEnded(true);
  };

  const handleSkip = () => {
    setIsVideoEnded(true);
  };

  useEffect(() => {
    if (isVideoEnded) {
      setTimeout(() => setIsPageLoaded(true), 200);
    }
  }, [isVideoEnded]);

  return (
    <div>
      {!isVideoEnded && <FullScreenVideo onVideoEnd={handleVideoEnd} onSkip={handleSkip} />}
      <div style={{ overflow: "hidden", position: "relative" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/Decor.png"}
          alt="Decor"
          style={{ width: "100%", height: "1000px", objectFit: "cover", display: isVideoEnded ? 'block' : 'none' }}
        />
        <Box
          sx={{
            position: "absolute",
            width: "45%",
            left: "5%",
            top: "20%",
            opacity: isPageLoaded ? 1 : 0,
            transition: "opacity 0.5s ease-in",
            display: isVideoEnded ? 'block' : 'none'
          }}
        >
          <Typography
            variant="h3"
            style={{
              fontFamily: "Montserrat",
              fontWeight: 750,
              color: "var(--body-text-color)",
              fontSize: "55pt",
            }}
          >
            Synergistic Intelligence
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "45%",
            height: "70px",
            left: "5%",
            top: "50%",
            opacity: isPageLoaded ? 1 : 0,
            transition: "opacity 0.5s ease-in 0.5s",
            display: isVideoEnded ? 'block' : 'none'
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 200,
              color: "var(--body-text-color)",
              fontSize: "16pt",
              transform: "translateY(-50%)",
              maxWidth: "100%",
            }}
          >
            Synergistic Intelligence revolutionizes teamwork by blending human
            creativity and AI's analytical prowess to transcend traditional
            boundaries, fostering a collective intelligence that drives
            <span style={{ fontWeight: 400, fontStyle: "italic" }}>
              {" "}
              holistic, groundbreaking innovation.
            </span>
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            color: "var(--body-text-color)",
            fontSize: "18pt",
            fontWeight: 400,
            maxWidth: "100%",
            position: "absolute",
            left: "5%",
            top: "60%",
            opacity: isPageLoaded ? 1 : 0,
            transition: "opacity 0.5s ease-in 1s",
            display: isVideoEnded ? 'block' : 'none'
          }}
        >
          Are you ready to unlock the full potential of your team's collective
          intelligence?
        </Typography>
        <Box
          border="2px solid var(--accent-color)"
          padding="1rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="50%"
          left="5%"
          position="absolute"
          top="70%"
          borderRadius="10px"
          maxWidth="150px"
          textAlign="center"
          fontWeight="600"
          sx={{
            opacity: isPageLoaded ? 1 : 0,
            transition: "opacity 0.5s ease-in 1.5s",
            display: isVideoEnded ? 'block' : 'none'
          }}
        >
          <Link
            to={"/about"}
            style={{ textDecoration: "none", color: "var(--body-text-color)" }}
          >
            Learn More &gt;&gt;
          </Link>
        </Box>
      </div>
    </div>
  );
}
