import { Box, Typography, Container, Button } from '@mui/material';
import { useState } from 'react';
import FeedbackForm from '../FeedbackForm';


export default function Explorer({name, handleRetakeAssessment}){
    const [showFeedbackForm, setShowFeedbackForm] = useState(false); 

    return (
        <Box>
            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', mr: 1 }}>
                        Discover Your Path to Growth
                    </Typography>
                    <img
                        src={process.env.PUBLIC_URL + '/images/growth.png'}
                        alt="Grow"
                        style={{
                            height: "8%", 
                            width: "8%",
                        }}
                    />
                </Box>
                
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Dear {name},

                    <br/><br/>
                    Congratulations 🎉 on completing the Survival Instinct Awareness Assessment! Your
                    results have revealed invaluable insights into how your survival instincts shape your
                    thoughts and actions. This awareness is the first step toward unlocking a more open
                    and growth-oriented mindset.

                    <br/><br/>
                    
                    <strong>Your Personalized Insights:</strong> Based on your responses, you are a <strong>Explorer 🧭</strong>. This
                    suggests that while you have a moderate awareness of your survival instincts, your
                    openness varies. In some areas of life, you’re eager and adaptable, while in others, you
                    hold firmly to your ideas, maintaining a sense of stability.
                </Typography>

                <Box sx={{display:'flex', justifyContent: 'space-evenly'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold' }}>Want To Retake The Assessment</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRetakeAssessment}
                            sx={{background: 'var(--accent-color)',
                                '&:hover': {
                                    backgroundColor: '#a962e3', // Color on hover
                                },}}
                        >
                            Retake Assessment
                        </Button>
                    </Box>
                    
                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold' }}>Want To Leave Direct Feedback</Typography>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => setShowFeedbackForm(true)}
                        >
                            Leave Direct Feedback
                        </Button>
                    </Box>
                </Box>
            </Container>

            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>
                <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2 }}>
                    Archetype: The Explorer
                </Typography>


                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <img
                    src={process.env.PUBLIC_URL + '/images/explorer.png'}
                    alt="Explorer"
                    style={{
                        height: "80%",
                        width: "45%",
                    }}
                    />

                    <Box sx={{ ml: 3, mt: 5 }}>
                        <Typography variant="body1" sx={{fontSize: '1.1rem'}}>
                            <strong>Key Traits:</strong> Curious, Cautious, Searching, Malleable, Semi-rigid
                        </Typography>

                        <Typography variant="body1" sx={{fontSize: '1.1rem', mt: 3 }}>
                            <strong>Your Journey:</strong> Like a moth drawn to a flickering flame, you’re driven by curiosity,
                            yet you carefully assess risks before leaping into the unknown. In some aspects
                            of life, you’re fluid and open, but in others, you remain steadfast, protecting your
                            sense of safety. This balance has served you well, but now it’s time to push the
                            boundaries and explore further.
                        </Typography>
                    </Box>

                </Box>

                <Typography variant="body1" sx={{ mb: 2, mt: 4, fontSize: '1.1rem'}}>
                    <strong>Why This Matters: </strong> <br/>
                    Your curiosity and openness have already allowed you to grow in many areas, but some
                    survival instincts may still be holding you back in other aspects of life. By recognizing
                    where you’re still resistant, you can gently challenge these instincts, uncover new
                    opportunities, and expand your horizons. Growth is not about leaving behind what
                    makes you feel secure—it&#39;s about integrating new experiences that can enhance your
                    journey. Imagine the possibilities when you balance your sense of safety with a
                    willingness to step into new, inspiring territory!

                    <br/><br/>
                    <strong>Keep Exploring: </strong> <br/>
                    Continue to push the boundaries of your comfort zone. Dive into resources that
                    challenge your current perspectives and connect with others who can offer fresh
                    insights. The more you explore, the more you'll find that even in areas where you’ve
                    been firm, there’s room for transformation and growth. Our community is here to support
                    your journey, offering you the tools and inspiration to become even more fluid in your
                    thinking and actions.

                    <br/><br/>
                    <strong>Intrigued?</strong> <br/>
                    Join a conversation on the SI platform to exchange ideas with fellow Explorers and
                    mentors. Share your unique blend of curiosity and caution and discover new ways to
                    explore the unfamiliar. Your journey toward deeper awareness and an even more
                    expansive life awaits—start the adventure today!
                </Typography>
            </Container>

            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>

                <Typography variant="body1" sx={{ mb: 2, fontSize: '1.1rem'}}>
                    <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2 }}>
                        Next Steps on Your Journey
                    </Typography>

                    <strong>1. Embrace New Prespectives: </strong> <br/>
                    Dive into The Fifth Discipline by Peter Senge. Focus on the sections about
                    Personal Mastery and Mental Models to deepen your understanding of how to
                    shift your mindset and welcome change more fully.

                    <br/><br/>
                    <strong>2. Join the SI Community: </strong> <br/>
                    Engage with others who share similar challenges and triumphs. Our community
                    offers a space for dialogue and mutual learning. Share your own journey and
                    listen to others’ stories—you might find new insights and ways to inspire others.

                    <br/><br/>
                    <strong>3. Deepen Your Awareness:</strong><br/>
                    Regular mindfulness practices can help you identify where your thought patterns
                    serve you and where they might hold you back. Consider meditation, journaling
                    about your experiences, or practicing grounding exercises to stay present and
                    more open in moments of uncertainty.

                    <br/><br/>
                    <strong>4. Be Adventurous:</strong><br/>
                    Embrace the challenge of stepping into the unknown. Try new activities and
                    observe how they make you feel. By building small, adventurous habits, you can
                    expand your comfort zone and discover new possibilities.

                    <br/><br/>
                    Warm regards, <br/>
                    <strong>The Synergistic Intelligence Team</strong>
                </Typography>
            </Container>

            <FeedbackForm open={showFeedbackForm} onClose={() => setShowFeedbackForm(false)} />
        </Box>
    );
}