// CreateComment.js

import React, { useState } from "react";
import api from "../../../api";
import { Box, Button, Typography } from "@mui/material";

import "../../../styles/styles.css";
import "../../../styles/tokens.css";

const inputStyle = {
  marginTop: "8px",
  backgroundColor: "#1c1f28",
  borderRadius: "10px",
  padding: "8px",
  width: "100%",
  outline: "1px solid grey",
};

export default function CreateComment({ user, post, setCommentFlag }) {
  const [commentData, setCommentData] = useState({
    author: user._id,
    date: null,
    content: "",
    likes: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCommentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreateComment = async (event) => {
    event.preventDefault();

    // Update the date in the postData
    const updatedCommentData = {
      ...commentData,
      date: new Date(),
    };

    try {
      const response = await api.post(
        `/api/forum/comments/${post._id}`,
        updatedCommentData
      );
      if (response.status === 201) {
        setCommentData({
          author: user._id,
          date: new Date(),
          content: "",
          likes: [],
        });

        setCommentFlag(true);
      }
    } catch (error) {
      console.error("Error creating comment:", error);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "10px",
        padding: "8px",
        backgroundColor: "#1c1f28",
        marginBottom: "20px",
      }}
    >
      <form onSubmit={handleCreateComment}>
        <Typography className="title">Comment as {user.name}</Typography>
        {/* Content Input */}
        <textarea
          id="content"
          name="content"
          placeholder="Say something..."
          rows={4}
          value={commentData.content}
          onChange={handleChange}
          className="sub-text"
          style={inputStyle}
        />

        <Box display="flex" justifyContent="flex-end" marginTop="10px">
          <Button
            type="submit"
            variant="contained"
            className="sub-text"
            sx={{
              color: "primary",
              borderRadius: "15px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Comment
          </Button>
        </Box>
      </form>
    </Box>
  );
}
