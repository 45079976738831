// NewReplyPost.js
import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";

const NewReplyPost = ({ discussionId, onAddReply }) => {
  const [replyContent, setReplyContent] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddReply(discussionId, replyContent);
    setReplyContent("");
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: "10px" }}>
      <TextField
        label="Add a reply"
        variant="outlined"
        fullWidth
        value={replyContent}
        onChange={(e) => setReplyContent(e.target.value)}
        sx={{ marginBottom: "10px", backgroundColor: "white", borderRadius: "10px" }}
      />
      <Button type="submit" variant="contained" color="primary">
        Add Reply
      </Button>
    </Box>
  );
};

export default NewReplyPost;
