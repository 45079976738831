// Resources.js

import React from "react";
import { Box } from "@mui/material";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function Resources() {
  return (
    <Box>
      <p style={{ color: "white" }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Tortor condimentum
        lacinia quis vel eros donec ac. Ac auctor augue mauris augue neque
        gravida in fermentum. Pulvinar elementum integer enim neque volutpat ac
        tincidunt vitae. Felis bibendum ut tristique et egestas quis ipsum
        suspendisse ultrices. Luctus venenatis lectus magna fringilla urna
        porttitor. Ipsum dolor sit amet consectetur adipiscing elit pellentesque
        habitant morbi. Malesuada nunc vel risus commodo viverra maecenas
        accumsan lacus vel. Orci ac auctor augue mauris augue neque gravida in.
        Ut tellus elementum sagittis vitae et leo duis ut diam. Lacus
        suspendisse faucibus interdum posuere lorem ipsum. Orci eu lobortis
        elementum nibh. Non quam lacus suspendisse faucibus interdum posuere
        lorem ipsum dolor. Eget lorem dolor sed viverra ipsum nunc. Purus ut
        faucibus pulvinar elementum integer enim. Cursus mattis molestie a
        iaculis at.
      </p>
    </Box>
  );
}
