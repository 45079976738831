// PostForm.js

import React, { useState } from "react";
import api from "../../api";
import {
  Box,
  Button,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "../../styles/styles.css";
import "../../styles/tokens.css";

const inputStyle = {
  marginTop: "16px",
  backgroundColor: "#1c1f28",
  borderRadius: "10px",
  padding: "8px",
  width: "100%",
  outline: "2px solid white",
};

const WhiteArrowDropDownIcon = (props) => {
  return <ArrowDropDownIcon style={{ color: "white" }} {...props} />;
};

export default function PostForm({ user, page, setForumComp }) {
  const [postData, setPostData] = useState({
    author: user._id,
    date: null,
    title: "",
    content: "",
    likes: [],
    tag: page.toLowerCase(page),
    comments: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPostData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreatePost = async (event) => {
    event.preventDefault();

    // Update the date in the postData
    const updatedPostData = {
      ...postData,
      date: new Date(),
    };

    try {
      const response = await api.post("/api/forum", updatedPostData);
      if (response.status === 201) {
        setPostData({
          author: user._id,
          date: new Date(),
          title: "",
          content: "",
          likes: [],
          tag: page.toLowerCase,
          comments: [],
        });
        setForumComp("feed-state");
      }
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  return (
    <Box
      sx={{ borderRadius: "10px", padding: "2rem", backgroundColor: "#1c1f28" }}
    >
      <Typography className="header">Create a post</Typography>

      <form onSubmit={handleCreatePost}>
        {/* Title Input */}
        <input
          id="title"
          name="title"
          type="text"
          placeholder="Title"
          value={postData.title}
          onChange={handleChange}
          className="sub-text"
          style={{
            ...inputStyle,
            height: "48px",
          }}
        />

        {/* Content Input */}
        <textarea
          id="content"
          name="content"
          placeholder="Content"
          rows={6}
          value={postData.content}
          onChange={handleChange}
          className="sub-text"
          style={inputStyle}
        />

        {/* Tag Input */}
        <FormControl
          fullWidth
          sx={{
            marginTop: "8px",
            marginBottom: "16px",
            backgroundColor: "#1c1f28",
            borderRadius: "10px",
            border: "2px solid white",
          }}
        >
          <Select
            value={postData.tag}
            onChange={handleChange}
            name="tag"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ color: "white" }}
            IconComponent={WhiteArrowDropDownIcon}
          >
            <MenuItem value={"real estate"}>Real Estate</MenuItem>
            <MenuItem value={"politics"}>Politics</MenuItem>
            <MenuItem value={"education"}>Education</MenuItem>
          </Select>
        </FormControl>

        {/* Submit Button */}
        <Box display="flex" justifyContent="flex-end" marginTop="10px">
          <Button
            type="submit"
            variant="contained"
            className="sub-text"
            sx={{
              color: "primary",
              borderRadius: "15px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Post
          </Button>
        </Box>
      </form>
    </Box>
  );
}
