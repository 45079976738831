// ForgotPassword.js

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import {
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../styles/styles.css";
import "../styles/tokens.css";

const invalidPasswordAlertMessage =
  "Invalid password. Password must contain:\n" +
  "  - at least 1 number\n" +
  "  - at least 1 special character\n" +
  "  - at least 1 uppercase letter\n" +
  "  - at least 1 lowercase letter";

const validatePassword = (password) => {
  const numberRegex = /\d/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;

  return (
    numberRegex.test(password) &&
    specialCharRegex.test(password) &&
    uppercaseRegex.test(password) &&
    lowercaseRegex.test(password)
  );
};

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") setEmail(value);
    if (name === "verificationCode") setVerificationCode(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/api/auth/forgot-password", { email });
      if (response.status === 200) {
        setStep(2);
        setSnackbarMessage("Verification code sent to your email.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Failed to send verification code. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error sending verification code:", error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();
    if (!validatePassword(newPassword)) {
      setErrorMessage(invalidPasswordAlertMessage);
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    setErrorMessage(""); // Clear the error message if password is valid and matches
    try {
      const response = await api.post("/api/auth/confirm-password", {
        email: email,
        verificationCode: verificationCode,
        newPassword: newPassword,
      });
      if (response.status === 200) {
        setSnackbarMessage("Password reset successful. You can now log in with your new password.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setSnackbarMessage("Failed to reset password. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      bgcolor="var(--primary-color)"
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        border="3px solid var(--accent-color)"
        padding="2rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        maxWidth="500px"
        borderRadius="10px"
        bgcolor="var(--primary-color)"
        flexDirection="column"
        textAlign="center"
      >
        <Typography
          className="text"
          style={{
            marginBottom: "1rem",
            fontSize: "1.5em",
            fontWeight: "bold",
          }}
        >
          {step === 1 ? "Forgot your password?" : "Reset Your Password"}
        </Typography>
        <Typography
          className="text"
          style={{
            marginBottom: "2rem",
            fontSize: "1em",
            color: "var(--body-text-color)",
          }}
        >
          {step === 1
            ? "Please enter the email you use to sign in."
            : "Enter the verification code sent to your email and your new password."}
        </Typography>

        {step === 1 ? (
          <form onSubmit={handleSubmitEmail} style={{ width: "100%" }}>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "16px" }}>
                <TextField
                  id="email"
                  name="email"
                  label="Email Address"
                  variant="standard"
                  fullWidth
                  onChange={handleChange}
                  InputLabelProps={{
                    className: "text",
                    style: { color: "var(--body-text-color)" },
                  }}
                  InputProps={{
                    className: "text",
                    style: { color: "var(--body-text-color)" }, // Apply the same color to the input text
                  }}
                  sx={{
                    borderBottom: "1px solid var(--body-text-color)",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{ height: "48px", backgroundColor: "#602EA0" }}
                  className="text"
                >
                  Request password reset
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Link
                  href="/login"
                  style={{
                    color: "var(--accent-color)",
                    textDecoration: "none",
                    fontSize: "0.9em",
                  }}
                  className="text"
                >
                  Back to Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        ) : (
          <form onSubmit={handleSubmitNewPassword} style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="verificationCode"
                  name="verificationCode"
                  label="Verification Code"
                  variant="standard"
                  fullWidth
                  value={verificationCode}
                  onChange={handleChange}
                  InputLabelProps={{
                    className: "text",
                    style: { color: "var(--body-text-color)" },
                  }}
                  InputProps={{
                    className: "text",
                    style: { color: "var(--body-text-color)" },
                  }}
                  sx={{
                    borderBottom: "1px solid var(--body-text-color)",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="standard"
                  style={{ borderBottom: "1px solid var(--body-text-color)" }}
                >
                  <InputLabel
                    htmlFor="standard-adornment-password"
                    style={{ color: "var(--body-text-color)" }}
                    className="text"
                  >
                    New Password
                  </InputLabel>
                  <Input
                    id="newPassword"
                    name="newPassword"
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          sx={{
                            color: "var(--body-text-color)",
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      color: "var(--body-text-color)",
                    }}
                    className="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="standard"
                  style={{ borderBottom: "1px solid var(--body-text-color)" }}
                >
                  <InputLabel
                    htmlFor="standard-adornment-confirm-password"
                    style={{ color: "var(--body-text-color)" }}
                    className="text"
                  >
                    Confirm Password
                  </InputLabel>
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          sx={{
                            color: "var(--body-text-color)",
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      color: "var(--body-text-color)",
                    }}
                    className="text"
                  />
                </FormControl>
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography
                    className="text"
                    style={{
                      color: "red",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#602EA0",
                    color: "#fff",
                    margin: "1rem 0",
                  }}
                  className="text"
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
