import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import NavigationBar from "./components/NavBar/NavigationBar";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import About from "./pages/About";
import BlindSpot from "./pages/BlindSpot";
import HTI from "./pages/HTI";
import TheoryU from "./pages/TheoryU";
import EditablePage from "./pages/EditablePage";
import AccountVerification from "./pages/AccountVerification";
import BlogPage from "./pages/BlogPage";
import BlogPost from "./pages/BlogPost";
import CreateBlog from "./components/Blog/CreateBlog";
import EditBlog from "./components/Blog/EditBlog";
import Assessment from "./components/Assessment/Assessment";
import AdditionalAssessment from "./components/Resources/AdditionalAssessment";
import QA from "./components/Assessment/QA";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import RealEstate from "./pages/RealEstate";
import Education from "./pages/Education";
import Politics from "./pages/Politics";
import Settings from "./pages/Settings";
import Payment from "./pages/Payment";
import Account from "./pages/Account";
import Help from "./pages/Help";


function App() {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/blind-spot" element={<BlindSpot />} />
        <Route path="/holistic-transcendent-innovation" element={<HTI />} />
        <Route path="/theory-u" element={<TheoryU />} />

        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path='/create-blog' element={<ProtectedRoute> <CreateBlog /> </ProtectedRoute>} />
        <Route path="/edit-blog/:id" element={<ProtectedRoute> <EditBlog /> </ProtectedRoute>} />

        <Route path="/editable-page/:tab/:title" element={<EditablePage />} />

        <Route path="/assessment" element={<Assessment />} />
        <Route path="/additional-assessment" element={<AdditionalAssessment />} />
        <Route path="/QA" element={<QA/>} />

        <Route
          path="/account-verification/:email"
          element={<AccountVerification />}
        />

        <Route path="/dashboard" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
        <Route path="/real-estate" element={<ProtectedRoute> <RealEstate /> </ProtectedRoute>} />
        <Route path="/education" element={<ProtectedRoute> <Education /> </ProtectedRoute>} />
        <Route path="/politics" element={<ProtectedRoute> <Politics /> </ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute> <Settings /> </ProtectedRoute>} />
        <Route path="/payment" element={<ProtectedRoute> <Payment /> </ProtectedRoute>} />
        <Route path="/account" element={<ProtectedRoute> <Account /> </ProtectedRoute>} />
        <Route path="/help" element={<Help />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
