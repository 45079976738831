// navigationPages.js

const pages = [
  { 
    name: 'SI', 
    link: null, 
    items: [
      { name: 'Overview', link: '/editable-page/SI/Overview' },
      { name: 'Personal Mastery', link: '/editable-page/SI/Personal%20Mastery' },
      { name: 'Mental Models', link: '/editable-page/SI/Mental%20Models' },
      { name: 'Shared Commitment', link: '/editable-page/SI/Shared%20Commitment'},
      { name: 'Team Learning', link: '/editable-page/SI/Team%20Learning' },
      { name: 'Role of AI', link: '/editable-page/SI/Role%20of%20AI' },
    ]
  },

  // { 
  //   name: 'HTI', 
  //   link: null, 
  //   items: [
  //     { name: 'Overview', link: '/editable-page/HTI/Overview' },
  //     { name: 'Initiation', link: '/editable-page/HTI/Initiation' },
  //     { name: 'Sensing', link: '/editable-page/HTI/Sensing' },
  //     { name: 'Presencing', link: '/editable-page/HTI/Presencing' },
  //     { name: 'Catalyzing', link: '/editable-page/HTI/Catalyzing' },
  //     { name: 'Prototyping', link: '/editable-page/HTI/Prototyping' },
  //     { name: 'Evolving', link: '/editable-page/HTI/Evolving' },
  //   ]
  // },

  // { 
  //   name: 'Blind Spot', 
  //   link: null, 
  //   items: [
  //     { name: 'Overview', link: '/editable-page/Blind%20Spot/Overview' },
  //     { name: 'Survival Instinct', link: '/editable-page/Blind%20Spot/Survival%20Instinct' },
  //     { name: 'Stagnant Identity', link: '/editable-page/Blind%20Spot/Stagnant%20Identity' },
  //     { name: 'Flow Identity', link: '/editable-page/Blind%20Spot/Flow%20Identity' },
  //     { name: 'Portal Mind', link: '/editable-page/Blind%20Spot/Portal%20Mind' },
  //   ]
  // },
  // { 
  //   name: 'SI Book', 
  //   link: null, 
  //   items: [
  //     { name: 'Welcome', link: '/editable-page/SI%20Book/Welcome' },
  //     { name: 'Inspiration', link: '/editable-page/SI%20Book/Inspiration' },
  //     { 
  //       name: 'Purpose', 
  //       link: '/editable-page/SI%20Book/Purpose',
        // items: [
        //   { name: 'True Joy', link: '/editable-page/SI%20Book/True%20Joy' },
        //   { name: 'Mission / Vision', link: '/editable-page/SI%20Book/Mission%20Vision' },
        //   { name: 'AI Apocalypse', link: '/editable-page/SI%20Book/AI%20Apocalypse' },
        //   { name: 'Discover Your Purpose', link: '/editable-page/SI%20Book/Discover%20Your%20Purpose' },
        // ]
      // },
      // { 
      //   name: 'Foundation', 
      //   link: null,
      //   items: [
      //     { name: 'The Fifth Discipline', link: '/editable-page/SI%20Book/The%20Fifth%20Discipline' },
      //     { name: 'Personal Mastery', link: '/editable-page/SI%20Book/Personal%20Mastery' },
      //     { name: 'Mental Models', link: '/editable-page/SI%20Book/Mental%20Models' },
      //     { name: 'Shared Commitment', link: '/editable-page/SI%20Book/Shared%20Commitment' },
      //     { name: 'Team Learning', link: '/editable-page/SI%20Book/Team%20Learning' },
      //     { name: 'Systems Thinking', link: '/editable-page/SI%20Book/Systems%20Thinking' },
      //     { name: 'Role of AI', link: '/editable-page/SI%20Book/Role%20of%20AI' },
      //   ]
      // },
      // { 
      //   name: 'The Blind Spot', 
      //   link: null,
      //   items: [
      //     { name: 'The Master Key', link: '/editable-page/SI%20Book/The%20Master%20Key' },
      //     { name: 'The Blind Spot', link: '/editable-page/SI%20Book/The%20Blind%20Spot' },
      //     { name: 'Awakening', link: '/editable-page/SI%20Book/Awakening' },
      //     { name: 'Survival Instinct', link: '/editable-page/SI%20Book/Survival%20Instinct' },
      //     { name: 'Stagnant Versus Flow', link: '/editable-page/SI%20Book/Stagnant%20Versus%20Flow' },
      //     { name: 'Wisdom of Insecurity', link: '/editable-page/SI%20Book/Wisdom%20of%20Insecurity' },
      //     { name: 'Unmediated Primary Knowing', link: '/editable-page/SI%20Book/Unmediated%20Primary%20Knowing' },
      //   ]
      // },
      // { 
      //   name: 'Your Portal Mind', 
      //   link: null,
      //   items: [
          // { name: 'Wu Wei', link: '/editable-page/SI%20Book/Wu%20Wei' },
          // { name: 'Portal AI', link: '/editable-page/SI%20Book/Portal%20AI' },
          // { name: 'Portal Mind', link: '/editable-page/SI%20Book/Portal%20Mind' },
        // ]
      // },
      // { 
      //   name: 'HTI', 
      //   link: '/editable-page/SI%20Book/HTI',
        // items: [
        //   { name: 'Initiation', link: '/editable-page/SI%20Book/Initiation' },
        //   { name: 'Downloading', link: '/editable-page/SI%20Book/Downloading' },
        //   { name: 'Sensing', link: '/editable-page/SI%20Book/Sensing' },
        //   { name: 'Letting Go', link: '/editable-page/SI%20Book/Letting%20Go' },
        //   { name: 'Presencing', link: '/editable-page/SI%20Book/Presencing' },
        //   { name: 'Letting Come', link: '/editable-page/SI%20Book/Letting%20Come' },
        //   { name: 'Crystalizing', link: '/editable-page/SI%20Book/Crystalizing' },
        // ]
  //     },
  //   ]
  // },
  // { name: 'Mentors', link: null,
  //   items: [
  //     { name: 'Teaching Theory-U', link: '/editable-page/Mentors/Teaching%20Theory-U' },
  //     { name: 'Discussion Forms', link: '/editable-page/Mentors/Discussion%20Forms' },
  //   ]
  // },
  { name: 'Assessment', link: null,
  items: [
    { name: 'Take the Assessment', link: '/assessment' },
    { name: 'Additional Resources', link: '/additional-assessment' },
    { name: 'Q&A + Feedback', link: '/QA'}
  ]
  },
  { name: 'Blogs', link: '/blog'},
  { name: 'About', link: '/about' },
];

export default pages;