// Politics.js

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import UserAuthData from "../components/UserAuthData";
import DashNavBar from "../components/DashNavBar";
import DashTabBar from "../components/DashTabBar";
import Resources from "../components/Resources";
import ChatBox from "../components/ChatBox";
import Forum from "../components/Forum";
import "../styles/styles.css";
import "../styles/tokens.css";

const drawerWidth = 300;
const page = "Politics";

export default function Politics() {
  const { userData, isLoading } = UserAuthData();
  const [tab, setTab] = useState("resources-state");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Adjust main box conponent based on window size
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderComponent = () => {
    const bodyComponentStyle = {
      position: "absolute",
      top: "18%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxHeight: "70vh",
      overflowY: "auto",
      p: 3,
    };

    let component;
    switch (tab) {
      case "resources-state":
        component = <Resources />;
        break;
      case "chat-state":
        component = <ChatBox />;
        break;
      case "forum-state":
        component = <Forum userData={userData} page={page} />;
        break;
      default:
        component = null;
    }

    return <div sx={{ ...bodyComponentStyle }}>{component}</div>;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>Please Log In to View</div>
  }
  return (
    <Box sx={{ position: "relative" }}>
      <DashNavBar userData={userData} page={page} />
      <Box
        component="main"
        sx={{
          backgroundColor: "var(--primary-color)",
          position: "absolute",
          top: "65px",
          left: windowWidth < 600 ? 0 : drawerWidth,
          width: `calc(100% - ${windowWidth < 600 ? 0 : drawerWidth}px)`,
          minHeight: "100vh",
          p: 3,
        }}
      >
        <Typography className="header" sx={{ color: "var(--accent-color)" }}>
          {page}
        </Typography>
        <DashTabBar tab={tab} setTab={setTab} />
        <Box style={{ marginTop: "5%" }}>{renderComponent()}</Box>
      </Box>
    </Box>
  );
}
