// UserAuthData.js

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";

export default function UserAuthData() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const idToken = localStorage.getItem("idToken");
      if (idToken) {
        try {
          const response = await api.get("/api/users/info", {
            params: {
              idToken: idToken,
            },
          });
          if (response && response.status !== 500) {
            setUserData(response.data.user);
          }
        } catch (error) {
          console.log(error.response);
        }
      } else {
        // navigate("/");
        setUserData(null);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [navigate]);

  return { userData, isLoading, setUserData };
}
