// src/components/FormNavigation.js
import React from 'react';
import { Box, Button, Stepper, Step, StepLabel } from '@mui/material';

const FormNavigation = ({ currentSection, handleNext, handleBack, isLastSection, sections }) => (
  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
    <Button
      variant="contained"
      color="secondary"
      sx={{ padding: '10px 20px', width: { xs: '100%', md: 'auto' } }}
      onClick={handleBack}
      disabled={currentSection === 0}
      type="button"
    >
      Back
    </Button>
    <Stepper activeStep={currentSection} alternativeLabel sx={{ flexGrow: 1, mx: { xs: 0, md: 2 }, width: '100%' }}>
      {sections.map((section, index) => (
        <Step key={index}>
          <StepLabel></StepLabel>
        </Step>
      ))}
    </Stepper>
    <Button
      variant="contained"
      color="primary"
      sx={{ padding: '10px 20px', width: { xs: '100%', md: 'auto' } }}
      onClick={isLastSection ? null : handleNext}
      type={isLastSection ? "submit" : "button"}
    >
      {isLastSection ? 'Submit' : 'Next'}
    </Button>
  </Box>
);

export default FormNavigation;
