import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function TheoryU() {
  return (
    <Box>
      <Box p={2} sx={{ marginTop: 4, marginLeft: 3, paddingTop: "65px" }}>
        <Typography variant="h3" className="title">
          Principles of Theory U
        </Typography>
      </Box>

      <Box p={2} sx={{ marginTop: 2, marginLeft: 3, width: "55%" }}>
        <Typography className="text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
          ullamcorper vulputate pharetra. Curabitur vitae ornare metus, vel
          ultrices lectus. Quisque leo nibh, hendrerit at porttitor fringilla,
          molestie vestibulum lectus. Praesent venenatis eros a augue vestibulum
          pharetra eu vitae odio. Morbi sapien nisl, maximus non felis ac,
          consequat ullamcorper libero. Etiam at ligula eu lectus eleifend
          sagittis id vitae magna.
        </Typography>
      </Box>
    </Box>
  );
}
