import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, TextField } from '@mui/material';
import ReactQuill from 'react-quill';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import '../../styles/styles.css';
import '../../styles/tokens.css';
import api from '../../api';

const EditBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await api.get(`/api/blogs/${id}`);
        const blog = response.data.blog;
        setTitle(blog.title);
        setDate(blog.date.split('T')[0]);
        setContent(blog.content);
        setImage(blog.image);
      } catch (error) {
        console.error('Error fetching blog post:', error);
      }
    };

    fetchBlog();
  }, [id]);

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('date', date);
      formData.append('content', content);
      if (imageFile) {
        formData.append('image', imageFile);
      }

      await api.patch(`/api/blogs/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate(`/blog/${id}`);
    } catch (error) {
      console.error('Error updating blog post:', error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '80px',
          backgroundColor: '#000000',
          color: 'white',
          minHeight: '100vh',
          paddingBottom: '40px'
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{
            input: { color: '#AF69ED', fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' },
            marginBottom: '20px',
            backgroundColor: 'black',
            borderRadius: '0.625rem'
          }}
        />

        <Container sx={{ textAlign: 'left', marginBottom: '20px', maxWidth: '100%' }}>
          <Typography variant="body2" sx={{ fontSize: '1.5rem', marginBottom: '20px', textAlign: 'center' }}>
            {date}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              id="upload-image"
            />
            <label htmlFor="upload-image">
              <Button variant="contained" color="primary" component="span">
                Upload Image
              </Button>
            </label>
          </Box>
          {image && (
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
              <img src={image} alt="Blog" style={{ width: '50%', height: 'auto', borderRadius: '10px' }} />
            </Box>
          )}
          <ReactQuill
            theme="snow"
            value={content}
            onChange={handleContentChange}
            style={{ height: '300px', marginBottom: '1rem', color: 'white', backgroundColor: 'black', borderRadius: '0.625rem' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button type="submit" onClick={handleSubmit} variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EditBlog;
