// PostItem.js

import React, { useState, useEffect } from "react";
import api from "../../api";
import { Box, Grid, Typography } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import "../../styles/styles.css";
import "../../styles/tokens.css";

export default function PostItem({
  user,
  post,
  setCurrPost,
  setForumComp,
  currForumComp,
  isClickable,
  commentFlag,
  setCommentFlag,
  setDeleteFlag,
}) {
  // Get author and like status
  const [userName, setUserName] = useState("");
  const [isLiked, setIsLiked] = useState(null);
  const [numLikes, setNumLikes] = useState(null);
  const [numComments, setNumComments] = useState(null);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await api.get(`/api/forum/author?id=${post.author}`);
        setUserName(response.data.author);
      } catch (error) {
        console.error("Error fetching user name:", error);
      }
    };

    const fetchPost = async () => {
      try {
        const response = await api.get(`/api/forum/${post._id}`);

        setNumLikes(response.data.post.likes.length);
        setIsLiked(response.data.post.likes.includes(user._id));

        setNumComments(response.data.post.comments.length);
        if (commentFlag) {
          setCommentFlag(false);
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchUserName();
    fetchPost();
  }, [post.author, post._id, user._id, isLiked, commentFlag, setCommentFlag]);

  // Date
  const dateObject = new Date(post.date);
  const formattedDate = dateObject.toLocaleDateString();
  const formattedTime = dateObject.toLocaleTimeString();

  // Add Like
  const handleLikeClick = async (event) => {
    event.stopPropagation();
    try {
      const response = await api.post(`/api/forum/likes/${post._id}`, {
        user_id: user._id,
      });
      if (response.status === 201) {
        setIsLiked(true);
      } else if (response.status === 204) {
        setIsLiked(false);
      }
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };

  // Delete comment
  const handleDeletePost = async (event) => {
    event.stopPropagation();
    try {
      const response = await api.delete(`/api/forum/${post._id}`);

      if (response.status === 200 && currForumComp === "feed-state") {
        setDeleteFlag(true);
      }
      // else if (
      //   response.status === 200 &&
      //   currForumComp === "comments-state"
      // ) {
      //   setForumComp("feed-state");
      // }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  return (
    <Box
      onClick={
        isClickable
          ? () => {
              setForumComp("comments-state");
              setCurrPost(post);
            }
          : undefined
      }
      sx={{
        borderRadius: "10px",
        padding: "8px",
        backgroundColor: "#1c1f28",
        marginBottom: "20px",
        "&:hover": {
          border: isClickable ? "1px solid white" : "none",
        },
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
            <span className="title">Posted by {userName}</span>
            <span className="forum-text">
              Date: {formattedDate}, Time: {formattedTime}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="header">{post.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="sub-text">{post.content}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className="forum-text"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <span style={{ display: "flex" }}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <ChatBubbleOutlineIcon sx={{ marginRight: "5px" }} />
                {numComments}
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                {isLiked ? (
                  <FavoriteIcon
                    sx={{ marginRight: "5px" }}
                    onClick={handleLikeClick}
                  />
                ) : (
                  <FavoriteBorderIcon
                    sx={{ marginRight: "5px" }}
                    onClick={handleLikeClick}
                  />
                )}
                {numLikes}
              </span>
            </span>
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <span>{post.tag}</span>
              {currForumComp !== "comments-state" &&
                user._id === post.author && (
                  <DeleteOutlineIcon
                    sx={{ marginLeft: "20px" }}
                    onClick={handleDeletePost}
                  />
                )}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
