import { Box, Typography, Container, Button } from '@mui/material';
import { useState } from 'react';
import FeedbackForm from '../FeedbackForm';


export default function Defender({name, handleRetakeAssessment}){
    const [showFeedbackForm, setShowFeedbackForm] = useState(false); 

    return (
        <Box>
            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', mr: 1 }}>
                        Discover Your Path to Growth
                    </Typography>
                    <img
                        src={process.env.PUBLIC_URL + '/images/growth.png'}
                        alt="Grow"
                        style={{
                            height: "8%", 
                            width: "8%",
                        }}
                    />
                </Box>
                
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Dear {name},

                    <br/><br/>
                    Congratulations 🎉 on completing the Survival Instinct Awareness Assessment! Your
                    results have revealed invaluable insights into how your survival instincts shape your
                    thoughts and actions. This awareness is the first step toward unlocking a more open
                    and growth-oriented mindset.

                    <br/><br/>
                    
                    <strong>Your Personalized Insights:</strong> Based on your responses, you are a <strong>DEFENDER 🛡️</strong>. Your
                    instincts are strong, making you naturally risk-averse and steadfast. While this brings
                    stability, it can also hold you back from realizing your full potential. 
                </Typography>

                <Box sx={{display:'flex', justifyContent: 'space-evenly'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold' }}>Want To Retake The Assessment</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRetakeAssessment}
                            sx={{background: 'var(--accent-color)',
                                '&:hover': {
                                    backgroundColor: '#a962e3', // Color on hover
                                },}}
                        >
                            Retake Assessment
                        </Button>
                    </Box>
                    
                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold' }}>Want To Leave Direct Feedback</Typography>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => setShowFeedbackForm(true)}
                        >
                            Leave Direct Feedback
                        </Button>
                    </Box>
                </Box>
            </Container>

            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>
                <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2 }}>
                    Archetype: The Defender
                </Typography>


                <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                    <img
                    src={process.env.PUBLIC_URL + '/images/Defender.png'}
                    alt="Defender"
                    style={{
                        height: "80%",
                        width: "45%",
                    }}
                    />

                    <Box sx={{ ml: 3, mt: 5 }}>
                        <Typography variant="body1" sx={{fontSize: '1.1rem'}}>
                            <strong>Key Traits:</strong> Thorough, Definitive, Risk-averse, Consistent, Unwavering
                        </Typography>

                        <Typography variant="body1" sx={{fontSize: '1.1rem', mt: 3 }}>
                            <strong>Your Journey:</strong> You value predictability and consistency. This often means you
                            shy away from new experiences, missing the opportunity to discover inspiring
                            and exciting aspects of life. The more you become aware of these instincts, the
                            more you can step into "the flow" and embrace growth.
                        </Typography>
                    </Box>

                </Box>

                <Typography variant="body1" sx={{ mb: 2, mt: 4, fontSize: '1.1rem'}}>
                    <strong>Why This Matters: </strong>Your survival instincts, while protective, can sometimes prevent you
                    from reaching your full potential. By gradually shifting these instincts, you open the door
                    to new opportunities, creativity, and personal growth. Imagine what becomes possible
                    when fear no longer holds you back!

                    <br/><br/>
                    <strong>Keep Exploring: </strong>Dive deeper into your results and explore our resources. The more
                    you understand your instincts, the more you can transform them into powerful tools for
                    innovation and growth.

                    <br/><br/>
                    <strong>Intrigued?</strong> Join a conversation on the SI platform or connect with one of our mentors to
                    explore your results further. Your journey to a more expansive and fulfilling life is just a
                    few clicks away.
                </Typography>
            </Container>

            <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: 4, borderRadius: 2, boxShadow: 3, marginBottom: '1rem'}}>

                <Typography variant="body1" sx={{ mb: 2, fontSize: '1.1rem'}}>
                    <Typography variant="h4" sx={{ fontWeight:'bold', mb: 2 }}>
                        Next Steps on Your Journey
                    </Typography>

                    <strong>1. Embrace New Prespectives: </strong> <br/>
                    Dive into The Fifth Discipline by Peter Senge. Particularly, explore the chapter
                    titled Nature’s Templates: Identifying the Patterns that Control Events to help you
                    shift your mindset and welcome change.

                    <br/><br/>
                    <strong>2. Join the SI Community: </strong> <br/>
                    Connect with others facing similar challenges. Our community is a safe space for
                    exploring new ideas and sharing growth experiences.

                    <br/><br/>
                    <strong>3. Build Your Awareness:</strong><br/>
                    Engage in regular mindfulness practices. Try guided meditation, journal your
                    daily experiences, or practice grounding techniques to increase your self-
                    awareness.

                    <br/><br/>
                    <strong>4. Challenge Your Comfort Zone:</strong><br/>
                    Take small, intentional steps outside your usual routine—try a new hobby or join
                    a thought-provoking conversation on our forums. You’ll find that even small
                    changes can lead to significant personal growth!

                    <br/><br/>
                    Warm regards, <br/>
                    <strong>The Synergistic Intelligence Team</strong>
                </Typography>
            </Container>

            <FeedbackForm open={showFeedbackForm} onClose={() => setShowFeedbackForm(false)} />
        </Box>
    );
}