import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DiscussionPost from "../components/Discussion/DiscussionPost";
import NewDiscussionPost from "../components/Discussion/NewDiscussionPost";
import api from "../api";

const CommunityPage = ({ pageId, title }) => {
  const [discussions, setDiscussions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDiscussions = async () => {
      try {
        const response = await api.get(`/api/pages/${pageId}/discussions`);
        setDiscussions(response.data.discussions);
      } catch (error) {
        console.error("Error fetching discussions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDiscussions();
  }, [pageId]);

  const handleAddDiscussion = async (content) => {
    try {
      const newDiscussion = {
        content,
        author: "AnonymousUser", 
        date: new Date(),
      };
      const response = await api.post(`/api/pages/${pageId}/discussions`, newDiscussion);
      setDiscussions([...discussions, response.data.added_discussion]);
    } catch (error) {
      console.error("Error adding discussion:", error);
    }
  };

  const handleAddReply = async (discussionId, replyContent) => {
    try {
      const newReply = {
        content: replyContent,
        author: "AnonymousUser", // Replace with actual user data
        date: new Date(),
        discussionId,
      };
      const response = await api.post(`/api/discussions/${discussionId}/replies`, newReply);
      const updatedDiscussions = discussions.map((discussion) => {
        if (discussion._id === discussionId) {
          return {
            ...discussion,
            replies: [...discussion.replies, response.data.added_reply],
          };
        }
        return discussion;
      });
      setDiscussions(updatedDiscussions);
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ color: "var(--accent-color)", marginBottom: 2 }}>
        {title}
      </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Box>
          {discussions.map((discussion) => (
            <DiscussionPost key={discussion._id} discussion={discussion} onAddReply={handleAddReply} />
          ))}
          <NewDiscussionPost onAddDiscussion={handleAddDiscussion} />
        </Box>
      )}
    </Box>
  );
};

export default CommunityPage;
