import React from "react";
import { Button, Typography } from "@mui/material";

const SignOutButton = ({ sx }) => {
  const handleSignOut = () => {
    // Clear tokens from local storage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("idToken");

    window.location.reload();
  };

  return (
    <Button sx={sx} onClick={handleSignOut}>
      <Typography
        className="nav-text"
        sx={{ fontWeight: 600, textDecoration: 'none', color: 'inherit' }}
      >
        Sign Out
      </Typography>
    </Button>
  );
};

export default SignOutButton;
