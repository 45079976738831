// CommentFeed.js

import React, { useState, useEffect } from "react";
import api from "../../../api";
import { Box } from "@mui/material";

// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CommentItem from "./CommentItem";

import "../../../styles/styles.css";
import "../../../styles/tokens.css";

export default function CommentFeed({ user, post, setCommentFlag }) {
  const [comments, setComments] = useState([]);
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await api.get(`/api/forum/${post._id}`);
        if (response.status === 200) {
          const com = response.data.post.comments;

          //   Sort comments by date in descending order (most recent first)
          const sortedComments = com.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setComments(sortedComments);
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [post._id, comments]);

  return (
    <Box>
      {comments.map((comment) => (
        <CommentItem
          key={comment._id}
          user={user}
          comment={comment}
          setCommentFlag={setCommentFlag}
        />
      ))}
    </Box>
  );
}
