import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, CircularProgress, Snackbar, Alert, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import UserAuthData from "../components/UserAuthData";
import "../styles/styles.css";
import "../styles/tokens.css";
import api from '../api';

const BlogPost = () => {
  const { id } = useParams();
  const { userData, isLoading: authLoading } = UserAuthData();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await api.get(`/api/blogs/${id}`);
        console.log("RESPONSE: ", response);
        setPost(response.data.blog);
        setLoading(false);
      } catch (error) {
        setError("Error fetching blog post");
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  const handleEditBlog = () => {
    navigate(`/edit-blog/${id}`);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteBlog = async () => {
    try {
      await api.delete(`/api/blogs/${id}`);
      setSnackbarMessage('Blog has been deleted successfully!');
      setSnackbarSeverity('success');  // Set severity to success
      setSnackbarOpen(true);

      setTimeout(() => {
        navigate('/blog');
      }, 1200);

    } catch (error) {
      setSnackbarMessage('Error deleting blog!');
      setSnackbarSeverity('error');  // Set severity to error
      setSnackbarOpen(true);
    }
  };

  if (authLoading || loading) {
    return (
      <>
        <Container sx={{ paddingTop: '80px', color: 'white' }}>
          <CircularProgress color="inherit" />
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Container sx={{ paddingTop: '80px', color: 'white' }}>
          <Typography variant="h4" component="h2">
            {error}
          </Typography>
        </Container>
      </>
    );
  }

  if (!post) {
    return (
      <>
        <Container sx={{ paddingTop: '80px', color: 'white' }}>
          <Typography variant="h4" component="h2">
            Post not found
          </Typography>
        </Container>
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '80px',
          backgroundColor: '#000000',
          color: 'white',
          minHeight: '100vh',
          paddingBottom: '40px'
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          sx={{
            color: '#AF69ED',
            fontWeight: 'bold',
            fontSize: '3rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            marginBottom: '20px'
          }}
        >
          {post.title}
        </Typography>

        <Container sx={{ textAlign: 'left', marginBottom: '20px', maxWidth: '100%' }}>
          <Typography variant="body2" sx={{ fontSize: '1.5rem', marginBottom: '20px', textAlign: 'center' }}>
            {new Date(post.date).toLocaleDateString()}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <img src={post.image} alt={post.title} style={{ width: '50%', height: 'auto', borderRadius: '10px' }} />
          </Box>

          <Typography
          className="text"
          sx={{ color: "white", whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{ __html: post.content }}
          />

          {userData && userData.role === 'admin' && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', gap: 2 }}>
            <Button variant="contained" color="primary" onClick={handleEditBlog}>
              Edit Blog
            </Button>

            <Button variant="contained" sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }} onClick={handleDeleteBlog}>
              Delete Blog
            </Button>
          </Box>
          )}
        </Container>
      </Box>

       {/* Snackbar component for showing feedback */}
       <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BlogPost;
