import React, { useState } from 'react';
import FeedbackForm from './FeedbackForm';
import { Box, Button, Typography, Container } from '@mui/material';
import CommunityPage from '../../pages/CommunityPage';

const QA = () => {
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    return (
        <Box sx={{ backgroundColor: "#f4f4f4", minHeight: "100vh", py: 4 }}>
            <Container 
                maxWidth="md" 
                sx={{ 
                    marginTop: '5rem',
                    backgroundColor: "white", 
                    color: "black", 
                    p: 4, 
                    borderRadius: 2, 
                    boxShadow: 3, 
                    mb: 4, 
                    textAlign: 'center'
                }}
            >
                <Typography variant="h3" sx={{ mb: 2, fontWeight: 'bold' }}>
                    Feedback and Q&A
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    Share your thoughts or ask questions below. We're here to help!
                </Typography>

                <Button variant="contained" color="primary" onClick={() => setShowFeedbackForm(true)} sx={{ fontWeight: 'bold' }}>
                    Give Direct Feedback
                </Button>

                <FeedbackForm open={showFeedbackForm} onClose={() => setShowFeedbackForm(false)} />
            </Container>

            <Container maxWidth="md">
                <CommunityPage pageId={'66b06656aee43157a89b29e3'} title={"Questions"} />
            </Container>
        </Box>
    );
}

export default QA;
