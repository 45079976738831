// Comments.js

import React, { useState } from "react";
import { Box } from "@mui/material";

import PostItem from "./PostItem";
import CreateComment from "./commentComponents/CreateComment";
import CommentFeed from "./commentComponents/CommentFeed";

import "../../styles/styles.css";
import "../../styles/tokens.css";

export default function Comments({ user, post, setForumComp }) {
  const [commentFlag, setCommentFlag] = useState(false);

  return (
    <Box>
      <PostItem
        user={user}
        post={post}
        setCurrPost={null}
        setForumComp={setForumComp}
        currForumComp={"comments-state"}
        isClickable={false}
        commentFlag={commentFlag}
        setCommentFlag={setCommentFlag}
        setDeleteFlag={null}
      />
      <CreateComment user={user} post={post} setCommentFlag={setCommentFlag} />
      <CommentFeed user={user} post={post} setCommentFlag={setCommentFlag} />
    </Box>
  );
}
