import React, { useState } from "react";
import { IconButton, Drawer, List, ListItem, ListItemText, Box, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

export default function HamburgerNavBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Toggle Drawer open and close
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // Function to check if the device is mobile
  const isMobile = window.innerWidth <= 600;

  return (
    <div>
      {/* Hamburger Icon Button */}
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        sx={{ position: "absolute", top: "10px", left: "10px" }}
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer for Hamburger Menu */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250, bgcolor: "black", color: "white", height: "100%" }} role="presentation">
          {/* Logo and Title (Visible only on mobile) */}
          {isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
              <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'white' }}>
                <img
                  src={process.env.PUBLIC_URL + '/logo76.png'}
                  alt="Logo"
                  style={{ marginRight: '10px', width: '40px', height: '40px' }}
                />
                <Box>
                  <Typography sx={{ fontSize: '1rem' }}>Synergistic</Typography>
                  <Typography sx={{ fontSize: '1rem' }}>Intelligence</Typography>
                </Box>
              </Link>
            </Box>
          )}

          {/* List Items with Accordion for Nested Links */}
          <List sx={{ bgcolor: "black" }}>
            {/* SI Section */}
            <Accordion sx={{ bgcolor: "black", color: "white" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />} disableGutters>
                <ListItemText primary="SI" sx={{ color: "white" }} />
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: "black" }}>
                <List component="div" disablePadding sx={{ bgcolor: "black" }}>
                  <ListItem button component={Link} to="/editable-page/SI/Overview" sx={{ color: "white" }}>
                    <ListItemText primary="Overview" sx={{ color: "white" }} />
                  </ListItem>
                  <ListItem button component={Link} to="/editable-page/SI/Personal%20Mastery" sx={{ color: "white" }}>
                    <ListItemText primary="Personal Mastery" sx={{ color: "white" }} />
                  </ListItem>
                  <ListItem button component={Link} to="/editable-page/SI/Mental%20Models" sx={{ color: "white" }}>
                    <ListItemText primary="Mental Models" sx={{ color: "white" }} />
                  </ListItem>
                  <ListItem button component={Link} to="/editable-page/SI/Shared%20Commitment" sx={{ color: "white" }}>
                    <ListItemText primary="Shared Commitment" sx={{ color: "white" }} />
                  </ListItem>
                  <ListItem button component={Link} to="/editable-page/SI/Team%20Learning" sx={{ color: "white" }}>
                    <ListItemText primary="Team Learning" sx={{ color: "white" }} />
                  </ListItem>
                  <ListItem button component={Link} to="/editable-page/SI/Role%20of%20AI" sx={{ color: "white" }}>
                    <ListItemText primary="Role of AI" sx={{ color: "white" }} />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {/* Assessment Section */}
            <Accordion sx={{ bgcolor: "black", color: "white" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />} disableGutters>
                <ListItemText primary="Assessment" sx={{ color: "white" }} />
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: "black" }}>
                <List component="div" disablePadding sx={{ bgcolor: "black" }}>
                  <ListItem button component={Link} to="/assessment" sx={{ color: "white" }}>
                    <ListItemText primary="Take the Assessment" sx={{ color: "white" }} />
                  </ListItem>
                  <ListItem button component={Link} to="/additional-assessment" sx={{ color: "white" }}>
                    <ListItemText primary="Additional Resources" sx={{ color: "white" }} />
                  </ListItem>
                  <ListItem button component={Link} to="/QA" sx={{ color: "white" }}>
                    <ListItemText primary="Q&A + Feedback" sx={{ color: "white" }} />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {/* Other Main Menu Items */}
            <ListItem button component={Link} to="/blog" sx={{ color: "white" }}>
              <ListItemText primary="Blogs" sx={{ color: "white" }} />
            </ListItem>
            <ListItem button component={Link} to="/about" sx={{ color: "white" }}>
              <ListItemText primary="About" sx={{ color: "white" }} />
            </ListItem>
            <ListItem button component={Link} to="/login" sx={{ color: "white" }}>
              <ListItemText primary="Sign in" sx={{ color: "white" }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}
