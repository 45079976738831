import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function About() {
  return (
    <Box>
      <div style={{ position: "relative", height: "auto" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/Ellipse.png"}
          alt="Ellipse"
          style={{
            top: "-350px",
            left: "-10%",
            position: "absolute",
          }}
        />

        <Box
          p={2}
          sx={{
            position: "relative",
            top: "200px",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" className="title" style={{ fontSize: "48" }}>
            What is Synergistic Intelligence?
          </Typography>
        </Box>
        <Box
          p={2}
          sx={{
            position: "relative",
            top: "230px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "55%",
            textAlign: "center",
          }}
        >
          <Typography className="text" style={{ textAlign: "center" }}>
            At Synergistic Intelligence, we specialize in fostering an
            environment where the sum is truly greater than its parts. Drawing
            inspiration from the groundbreaking concepts in “The Fifth
            Discipline” by Peter Senge and David Bohm’s “On Dialogue,” we have
            developed a unique approach that leverages the emergent property of
            group intelligence.
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "600px", // Adjust based on your layout
            left: 0,
            width: "100%",
            height: "auto",
            backgroundColor: "white",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "top",
            padding: "80px 0px",
          }}
        >
          <Typography
            component="h2"
            className="pop"
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              marginLeft: "5%",
              maxWidth: "calc(100% - 70%)",
            }}
          >
            Holistic, transcendent innovation.
          </Typography>

          {/* Paragraph of text */}
          <Typography
            className="text"
            sx={{
              width: "calc(100% - 55%)",
              color: "black",
              marginRight: "12%",
            }}
          >
            Our methodology is centered on creating an atmosphere of open
            communication, shared understanding, and mutual respect, crucial for
            nurturing what we call ‘Synergistic Intelligence.’ This is not just
            about pooling individual knowledge; it’s about creating something
            greater – a collective cognitive capability that transcends
            individual capacities.
            <br />
            <br />
            Imagine your team not just sharing ideas, but collectively evolving
            them. With our guidance, teams learn to think together in a
            coordinated and aligned manner, tapping into a holistic and
            transcendent form of innovation. This is where breakthroughs happen
            – innovations that don’t just improve upon existing ideas but leap
            ahead to new horizons.
            <br />
            <br />
            Moreover, we integrate Artificial Intelligence as an active team
            member. This isn’t just about harnessing AI as a tool; it’s about
            integrating its vast analytical capabilities into your team’s
            collaborative processes. AI complements human creativity, empathy,
            and contextual understanding, propelling your team towards more
            comprehensive problem-solving and decision-making.
            <br />
            <br />
            In the spirit of Peter Senge’s systems thinking, we help teams see
            the bigger picture, understand interconnections, and find patterns
            that lead to deeper insights. Similarly, aligning with David Bohm’s
            emphasis on dialogue, our AI integration fosters more informed
            discussions, offering diverse perspectives and data-driven scenarios
            for a richer shared understanding.
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "560px", // Slightly above the white box to overlap
            left: "50%",
            width: "0",
            height: "0",
            borderLeft: "70px solid transparent", // Adjust size of the triangle here
            borderRight: "70px solid transparent", // Adjust size of the triangle here
            borderBottom: "40px solid white", // The color and size of the triangle
            transform: "translateX(-50%)",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            top: "1360px",
            width: "100%",
            height: "250px",
            backgroundColor: "var(--primary-color)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "80px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "2rem",
              width: "80%",
              border: "5px solid",
              borderRadius: "20px",
              borderColor: "var(--accent-color)",
              boxSizing: "border-box",
              justifyContent: "center",
            }}
          >
            <Typography
              className="text"
              sx={{
                width: "75%",
                color: "white",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              Choose Synergistic Intelligence, and let’s embark on a journey to
              transform your team’s collaborative potential into holistic,
              transcendent innovation. Let’s redefine what’s possible together.
            </Typography>
          </Box>
        </Box>
      </div>
    </Box>
  );
}
