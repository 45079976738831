// Forum.js

import React, { useState } from "react";
import { Box, Button } from "@mui/material";

import Feed from "./forumComponents/Feed";
import CreatePost from "./forumComponents/CreatePost";
import SearchPost from "./forumComponents/SearchPost";
import Comments from "./forumComponents/Comments";

import "../styles/styles.css";
import "../styles/tokens.css";

export default function Forum({ userData, page }) {
  const [forumComp, setForumComp] = useState("feed-state");
  const [currPost, setCurrPost] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [useSearchParams, setFlag] = useState(false); // Flag that determines whether feed will populate using default search params or those from searchParams

  // Function to update the flag
  const updateFlag = (newFlag) => {
    setFlag(newFlag);
  };

  const updateSearchParams = (params) => {
    setSearchParams(params);
    setForumComp("feed-state"); // Switch back to feed view after search
  };

  const BackButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{
            color: "white",
            outline: "2px solid white",
            marginBottom: "8px",
          }}
          onClick={() => setForumComp("feed-state")}
        >
          Back
        </Button>
      </div>
    );
  };

  const renderForumComp = () => {
    let component;
    switch (forumComp) {
      case "feed-state":
        // setIsClickable(true);
        component = (
          <Feed
            user={userData}
            page={page}
            setCurrPost={setCurrPost}
            setForumComp={setForumComp}
            searchParams={searchParams}
            useSearchParams={useSearchParams}
            updateFlag={updateFlag}
          />
        );
        break;
      case "form-state":
        component = (
          <div>
            <BackButton />
            <CreatePost
              user={userData}
              page={page}
              setForumComp={setForumComp}
            />
          </div>
        );
        break;
      case "search-state":
        component = (
          <div>
            <BackButton />
            <SearchPost
              updateSearchParams={updateSearchParams}
              updateFlag={updateFlag}
              page={page}
            />
          </div>
        );
        break;
      case "comments-state":
        component = (
          <div>
            <BackButton />
            <Comments
              user={userData}
              post={currPost}
              setForumComp={setForumComp}
            />
          </div>
        );
        break;
      default:
        component = null;
    }

    return component;
  };

  // useEffect(() => {
  //   renderForumComp();
  // }, [forumComp, renderForumComp]);

  return <Box>{renderForumComp()}</Box>;
}
