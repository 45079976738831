// SignUp.js

import React, { useState } from "react";
import api from "../api";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../styles/styles.css";
import "../styles/tokens.css";

const invalidPasswordAlertMessage =
  "Invalid password. Password must contain:\n" +
  "  - at least 1 number\n" +
  "  - at least 1 special character\n" +
  "  - at least 1 uppercase letter\n" +
  "  - at least 1 lowercase letter";

export default function SignUp() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  async function addUserToUserPool(email, password) {
    try {
      const response = await api.post("/api/auth/register", {
        email: email,
        password: password,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async function addUserToDB(email, role, userSub) {
    try {
      const response = await api.post("/api/users", {
        name: email,
        role: role.toLowerCase(),
        sub_id: userSub,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async function handleCreateAccount(e, user) {
    e.preventDefault(); // Prevent the default form behavior
    try {
      // Confirm matching passwords
      if (user.password !== user.confirmPassword) {
        alert("Passwords do not match");
        return;
      }

      // Add user to cognito user pool
      const cognitoResp = await addUserToUserPool(user.email, user.password);
      if (cognitoResp && cognitoResp.status === 201) {
        // Add user to mongoDB
        const mongoResp = await addUserToDB(
          user.email,
          user.role,
          cognitoResp.data.UserSub
        );
        if (mongoResp && mongoResp.status !== 500) {
          navigate(`/account-verification/${user.email}`);
        }
        // Reset userData
        setUserData({
          email: "",
          password: "",
          confirmPassword: "",
          role: "",
        });
      } else if (cognitoResp && cognitoResp.status === 400) {
        if (cognitoResp.data.code === "InvalidPasswordException") {
          alert(invalidPasswordAlertMessage);
        } else {
          console.error(
            "Failed to add user to cognito user pool:",
            cognitoResp.data.message
          );
        }
      } else {
        console.error("Unexpected response:", cognitoResp);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  }

  return (
    <Box
      bgcolor="var(--primary-color)"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      <img
        src={process.env.PUBLIC_URL + "/images/Oval.png"}
        alt="Oval"
        style={{
          width: "50px",
          height: "50px",
          position: "absolute",
          top: "50px",
          left: "50px",
        }}
      />
      <Typography
        className="text"
        style={{
          position: "absolute",
          top: "20%",
          left: "50px",
          width: "500px",
          fontSize: "25px",
        }}
      >
        Join us in transcedant innovation.
      </Typography>

      <Box
        border="3px solid var(--accent-color)"
        padding="5rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="50%"
        left="40%"
        position="absolute"
        top="15%"
        borderRadius="50px"
        maxWidth="500px"
      >
        <Box>
          <form onSubmit={(e) => handleCreateAccount(e, userData)}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className="header" style={{ marginBottom: "16px" }}>
                  Create Account
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "16px" }}>
                <TextField
                  id="email"
                  name="email"
                  label="Email Address"
                  variant="standard"
                  fullWidth
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { color: "var(--body-text-color" },
                    className: "text",
                  }}
                  InputProps={{
                    style: { color: "var(--body-text-color)" }, // Apply the same color to the input text
                    className: "text",
                  }}
                  sx={{
                    borderBottom: "1px solid var(--body-text-color)",
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "16px" }}>
                <FormControl
                  fullWidth
                  variant="standard"
                  style={{ borderBottom: "1px solid var(--body-text-color)" }}
                >
                  <InputLabel
                    htmlFor="standard-adornment-password"
                    style={{ color: "var(--body-text-color" }}
                    className="text"
                  >
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          sx={{
                            color: "var(--body-text-color)",
                          }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      color: "var(--body-text-color)",
                    }}
                    className="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "16px" }}>
                <FormControl
                  fullWidth
                  variant="standard"
                  style={{ borderBottom: "1px solid var(--body-text-color)" }}
                >
                  <InputLabel
                    htmlFor="standard-adornment-confirm-password"
                    style={{ color: "var(--body-text-color" }}
                    className="text"
                  >
                    Confirm Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-confirm-password"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          sx={{
                            color: "var(--body-text-color)",
                          }}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      color: "var(--body-text-color)",
                    }}
                    className="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "48px" }}>
                <FormControl
                  variant="standard"
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid var(--body-text-color)",
                  }}
                >
                  <InputLabel
                    id="role-select-standard-label"
                    style={{ color: "var(--body-text-color" }}
                    className="text"
                  >
                    Role
                  </InputLabel>
                  <Select
                    labelId="role-label"
                    id="role"
                    name="role"
                    label="Role"
                    value={userData.role}
                    onChange={handleChange}
                    sx={{
                      color: "var(--body-text-color)",
                      // Target the icon specifically
                      "& .MuiSelect-icon": {
                        color: "var(--body-text-color)", // Use CSS variable or direct color value
                      },
                    }}
                  >
                    <MenuItem value={"Individual"} className="text">
                      Individual
                    </MenuItem>
                    <MenuItem value={"Company"} className="text">
                      Company
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "48px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{ height: "48px", backgroundColor: "#602EA0" }}
                  className="text"
                >
                  Create Account
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="var(--body-text-color)"
                  align="left"
                  className="text"
                >
                  Already have an account?{" "}
                  <Link to="/login" style={{ color: "var(--accent-color)" }}>
                    Sign in
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
