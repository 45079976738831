import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function BlindSpot() {
  return (
    <Box>
      <div style={{ position: "relative", height: "auto" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/Ellipse.png"}
          alt="Ellipse"
          style={{
            top: "-350px",
            left: "-10%",
            position: "absolute",
          }}
        />

        <Box
          p={2}
          sx={{
            position: "relative",
            top: "200px",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" className="title" style={{ fontSize: "48" }}>
            The “Blind Spot”
          </Typography>
        </Box>
        <Box
          p={2}
          sx={{
            position: "relative",
            top: "230px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "55%",
            textAlign: "center",
          }}
        >
          <Typography className="text" style={{ textAlign: "center" }}>
            In a world propelled by AI and digital advancement, our traditional
            structures falter, unable to keep pace. But within this maelstrom
            lies opportunity. By embracing adaptability and recognizing
            ourselves as dynamic entities of flow, we unlock the potential to
            thrive amidst change. Welcome to a world where fluidity is strength
            and the only certainty is change.
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "600px", // Adjust based on your layout
            left: 0,
            width: "100%",
            height: "auto",
            backgroundColor: "white",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "top",
            padding: "80px 0px",
          }}
        >
          <Typography
            component="h2"
            className="pop"
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              marginLeft: "5%",
              maxWidth: "calc(100% - 70%)",
            }}
          >
            The “blind spot” that blocks the ability to generate transformative
            innovation
          </Typography>

          {/* Paragraph of text */}
          <Typography
            className="text"
            sx={{
              width: "calc(100% - 55%)",
              color: "black",
              marginRight: "12%",
            }}
          >
            In the labyrinthine complexity of human existence, our minds have
            long been architects of an intricate illusion—a world delineated by
            solid, immutable structures. Countries, governments, companies,
            organizations, families, persons, and self-identities: these are the
            bedrocks upon which we've constructed our understanding of the
            world. Like ancient masons, our brains have meticulously laid each
            stone, crafting edifices that stand as testament to our craving for
            permanence and stability. Yet, the mortar that binds these stones is
            not as indomitable as we once believed. It is, in essence, a mirage,
            birthed from a necessity to navigate the vast, uncharted territories
            of life.
            <br />
            <br />
            Historically, the tectonic plates of change shifted with the
            lethargic grace of geological time, allowing these constructs to
            stand seemingly eternal. Our survival depended on the ability to
            build these solid frameworks of understanding, to map out a world
            that felt predictable and secure. Within these walls, we sought
            refuge from the capricious whims of nature and the uncertainty that
            shadows our existence. However, we now stand at the precipice of a
            new era, one marked by the relentless pace of change, catalyzed by
            the advent of artificial intelligence and the digital revolution.
            The once-glacial progress of societal, technological, and personal
            evolution has accelerated into a rapid current, transforming the
            landscape of our lives with unprecedented speed. In this maelstrom
            of change, the solid structures of old—our traditional bastions of
            security—are proving to be not just inadequate but
            counterproductive.
            <br />
            <br />
            The survival mechanism of the past, predicated on creating and
            relying upon unchanging frameworks, is yielding to a new imperative:
            adaptability. The crux of this evolutionary pivot lies in
            reconceptualizing our understanding of the world and ourselves from
            solid entities to entities of flow. Just as a river is never the
            same from one moment to the next, so too must we recognize that the
            entities we've long perceived as static—our institutions, our
            societies, and indeed, our very selves—are in perpetual flux.
            <br />
            <br />
            Embracing our identities as flow entities invites a radical
            reimagining of existence. Our bodies, the vessels through which we
            navigate the world, are in constant change, cells dying and being
            born anew in a never-ending cycle. Our personalities, far from being
            monolithic, evolve with every experience, every challenge, and every
            joy. Recognizing this intrinsic fluidity is the first step toward
            constructing a worldview that is not only resilient in the face of
            rapid change but thrives within it.
            <br />
            <br />
            The disruption brought about by AI serves as both a mirror and a
            catalyst, reflecting our own inherent adaptability while pushing us
            toward a greater embrace of the flow state. In this new paradigm,
            the creation of flow entities—dynamic, adaptable structures capable
            of evolving in real-time—becomes the cornerstone of survival.
            Governments, businesses, and social institutions that can morph in
            response to emerging challenges and opportunities will define the
            new era of human prosperity.
            <br />
            <br />
            This shift from solidity to flow represents a profound
            transformation in how we conceive of existence itself. It calls for
            a reevaluation of our most fundamental assumptions and an openness
            to a world that is not fixed but is constantly remaking itself in
            the image of flow. It is a world where adaptability is the currency
            of survival, where fluidity is strength, and where the only
            certainty is change.
            <br />
            <br />
            In the dance of existence, we are all participants, moving to the
            rhythm of transformation. As we embrace our flow identities, we
            unlock the potential to navigate the rapids of change with grace and
            agility, building not just structures that last but lives that
            flourish in the ever-shifting sands of time. The future beckons with
            the promise of infinite possibility, but only for those willing to
            let go of the shore and embrace the flow.
          </Typography>
        </Box>
      </div>
    </Box>
  );
}
