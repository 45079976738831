// src/components/Recommendations.js
import React from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { recommendations } from "./AssessmentQuestions";

const Recommendations = ({ sectionScores }) => (
  <Container maxWidth="md" sx={{ backgroundColor: "white", color: "black", p: { xs: 2, md: 4 }, borderRadius: 2, boxShadow: 3, marginBottom: '1rem' }}>
    <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: { xs: '1.25rem', md: '2rem' } }}>Recommendations</Typography>
    {sectionScores
      .filter(section => section.totalScore <= 5)
      .map((section, index) => {
        const recommendation = recommendations.find(rec => rec.section === section.section);
        return (
          <Card key={index} sx={{ mt: 2, mb: 2, backgroundColor: "#f5f5f5" }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: { xs: '1rem', md: '1.25rem' } }}>{section.section}</Typography>
              <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>
                We recommend exploring more about {section.section} to enhance your growth.
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" component={Link} to={recommendation.link} rel="noopener noreferrer">
                Learn More
              </Button>
            </CardActions>
          </Card>
        );
      })}
  </Container>
);

export default Recommendations;
