import { useEffect } from "react";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function ChatBox({ tab }) {
  useEffect(() => {
    (function (d, t) {
      var v = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      v.onload = function () {
        window.voiceflow.chat.load({
          verify: { projectID: "6654d354be8af8447791708f" },
          url: "https://general-runtime.voiceflow.com",
          versionID: "production",
          render: {
            mode: "embedded",
            target: document.getElementById("voiceflow-chat-frame"),
          },
          autostart: false,
        });
      };
      v.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
      v.type = "text/javascript";
      s.parentNode.insertBefore(v, s);
    })(document, "script");
  }, [tab]);

  return (
    <div style={{ display: "flex", height: "70vh" }}>
      <div
        style={{ flex: "6", marginRight: "2rem", height: "100%" }}
        id="voiceflow-chat-frame"
      > </div>
    </div>
  );
}
