export const sections = [
    {
      title: 'Understanding Stagnant Identity',
      questions: [
        { question: 'I often resist new ideas or changes in my routine.', type: 'negative' },
        { question: 'I experience anxiety or discomfort when faced with change.', type: 'negative' },
        { question: 'I am open to new concepts and ideas.', type: 'positive' },
        { question: 'I enjoy trying new things and exploring new routines.', type: 'positive' },
      ],
    },
    {
      title: 'Exploring Survival Instincts',
      questions: [
        { question: 'I feel the need to protect my self-image in social or professional settings.', type: 'negative' },
        { question: 'I frequently compare myself to others.', type: 'negative' },
        { question: 'I am comfortable with my self-image and do not feel the need to defend it.', type: 'positive' },
        { question: 'I focus on my own growth rather than comparing myself to others.', type: 'positive' },
      ],
    },
    {
      title: 'Recognizing Ego Defensiveness',
      questions: [
        { question: 'I feel defensive and justify my actions when receiving constructive criticism.', type: 'negative' },
        { question: 'I find it difficult to admit when I am wrong.', type: 'negative' },
        { question: 'I appreciate constructive feedback and use it to improve.', type: 'positive' },
        { question: 'I am open to admitting my mistakes and learning from them.', type: 'positive' },
      ],
    },
    {
      title: 'Shifting Towards Flow Identity',
      questions: [
        { question: 'I actively seek new experiences and learning opportunities.', type: 'positive' },
        { question: 'I engage in activities outside my comfort zone frequently.', type: 'positive' },
        { question: 'I avoid challenges that push me out of my comfort zone.', type: 'negative' },
        { question: 'I resist change and prefer to stay in my comfort zone.', type: 'negative' },
      ],
    },
    {
      title: 'Cultivating Openness and Awareness',
      questions: [
        { question: 'I am comfortable with uncertainty and not having all the answers.', type: 'positive' },
        { question: 'I practice mindfulness or other forms of self-reflection regularly.', type: 'positive' },
        { question: 'I find it hard to consider viewpoints that differ from my own.', type: 'negative' },
        { question: 'I struggle to understand my biases and limitations.', type: 'negative' },
      ],
    },
    {
      title: 'Deeper Personal Reflection',
      questions: [
        { question: 'My current identity is mostly defined by external achievements and recognition.', type: 'negative' },
        { question: 'There are aspects of myself that I often suppressed or ignored.', type: 'negative' },
        { question: 'I reflect on my past experiences to gain insights into my behavior and choices.', type: 'positive' },
        { question: 'I seek to understand the underlying motivations behind my actions and decisions.', type: 'positive' },
      ],
    },
  ];
  
  export const recommendations = [
    {
      section: 'Understanding Stagnant Identity',
      link: '/editable-page/SI/Understanding Stagnant Identity',
    },
    {
      section: 'Exploring Survival Instincts',
      link: '/editable-page/SI/Exploring Survival Instincts',
    },
    {
      section: 'Recognizing Ego Defensiveness',
      link: '/editable-page/SI/Recognizing Ego Defensiveness',
    },
    {
      section: 'Shifting Towards Flow Identity',
      link: '/editable-page/SI/Shifting Towards Flow Identity',
    },
    {
      section: 'Cultivating Openness and Awareness',
      link: '/editable-page/SI/Cultivating Openness and Awareness',
    },
    {
      section: 'Deeper Personal Reflection',
      link: '/editable-page/SI/Deeper Personal Reflection',
    },
  ];
  
  export const scoreMapping = {
    positive: { 1: -2, 2: -1, 3: 0, 4: 1, 5: 2 },
    negative: { 1: 2, 2: 1, 3: 0, 4: -1, 5: -2 }
  };

