// NewDiscussionPost.js
import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";

const NewDiscussionPost = ({ onAddDiscussion }) => {
  const [content, setContent] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddDiscussion(content);
    setContent("");
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: "10px" }}>
      <TextField
        label="Add a new discussion"
        variant="outlined"
        fullWidth
        value={content}
        onChange={(e) => setContent(e.target.value)}
        sx={{ marginBottom: "10px", backgroundColor: "white", borderRadius: "10px" }}
      />
      
      <Button type="submit" variant="contained" color="primary">
        Add Discussion
      </Button>
    </Box>
  );
};

export default NewDiscussionPost;
