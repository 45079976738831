import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, Typography, ListItemText, ListItemIcon } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const DropdownMenu = ({ anchorEl, handleCloseMenu, items }) => {
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
  const [nestedItems, setNestedItems] = useState([]);

  const handleOpenNestedMenu = (event, nestedItems) => {
    setNestedAnchorEl(event.currentTarget);
    setNestedItems(nestedItems);
  };

  const handleCloseNestedMenu = () => {
    setNestedAnchorEl(null);
    setNestedItems([]);
  };

  const menuItemStyle = {
    '&:hover': {
      backgroundColor: '#d3d3d3'
    },
  };

  const iconStyle = {
    color: 'var(--accent-color)',
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            borderRadius: 10,
            backgroundColor: 'var(--nav-color)',
          },
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.name}
            onClick={
              item.items ? (event) => handleOpenNestedMenu(event, item.items) : handleCloseMenu
            }
            sx={menuItemStyle}
          >
            <ListItemText>
              <Link to={item.link || '#'} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography className='text'>{item.name}</Typography>
              </Link>
            </ListItemText>
            {item.items && <ListItemIcon><ArrowRightIcon sx={iconStyle} /></ListItemIcon>}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={nestedAnchorEl}
        open={Boolean(nestedAnchorEl)}
        onClose={handleCloseNestedMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: 10,
            backgroundColor: 'var(--nav-color)',
          },
        }}
      >
        {nestedItems.map((item) => (
          <MenuItem
            key={item.name}
            onClick={handleCloseNestedMenu}
            sx={menuItemStyle}
          >
            <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography className='text'>{item.name}</Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownMenu;
