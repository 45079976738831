import React, { useEffect, useState } from 'react';
import { Box, FormControl, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';

const Question = ({ question, answer, onAnswer, isActive }) => {
  const [selectedValue, setSelectedValue] = useState(answer || '');

  useEffect(() => {
    setSelectedValue(answer || '');
  }, [answer]);

  const scale = [1, 2, 3, 4, 5];

  const getColor = (value) => {
    if (value === 1 || value === 2) {
      return '#8e24aa'; // Purple for Disagree
    }
    if (value === 4 || value === 5) {
      return '#43a047'; // Green for Agree
    }
    return '#b0bec5'; // Gray for Neither Agree nor Disagree
  };

  const handleChange = (e) => {
    const value = parseInt(e.target.value);
    setSelectedValue(value);
    onAnswer(value);
  };

  return (
    <Box sx={{ 
      textAlign: 'center', 
      marginBottom: { xs: 2, sm: 3, md: 4 }, 
      p: { xs: 1, sm: 2 }, 
      borderRadius: 2, 
      border: isActive ? '2px solid #1976d2' : '1px solid #e0e0e0', 
      boxShadow: isActive ? '0 0 10px rgba(25, 118, 210, 0.5)' : 'none'
    }}>
      <Typography variant="h6" sx={{ marginBottom: { xs: 1, sm: 2 }, fontWeight: 'bold', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
        {question}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: { xs: 1, sm: 2 } }}>
        <Typography variant="body1" sx={{ marginRight: 2, color: '#8e24aa', fontWeight: 'bold', fontSize: { xs: '0.5rem', sm: '1rem' } }}>
          Strongly Disagree
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup row aria-label={question} name={question} value={selectedValue} onChange={handleChange}>
            {scale.map((value) => (
              <FormControlLabel
                key={value}
                value={value.toString()}
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: getColor(value),
                      },
                      '&:not(.Mui-checked)': {
                        color: getColor(value),
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: { xs: 15, sm: 24, md: 28 },
                      },
                    }}
                  />
                }
                label=""
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Typography variant="body1" sx={{ marginLeft: 2, color: '#43a047', fontWeight: 'bold', fontSize: { xs: '0.5rem', sm: '1rem' } }}>
          Strongly Agree
        </Typography>
      </Box>
    </Box>
  );
};

export default Question;
