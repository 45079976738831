import React, { useEffect, useRef } from 'react';
import { Button } from '@mui/material';

const FullScreenVideo = ({ onVideoEnd, playbackRate = 1.75, onSkip }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play();
      videoElement.playbackRate = playbackRate;
      videoElement.addEventListener('ended', onVideoEnd);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', onVideoEnd);
      }
    };
  }, [onVideoEnd, playbackRate]);

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
      <video ref={videoRef} style={{ width: '100%', height: '100%', objectFit: 'cover' }} autoPlay muted playsInline>
        <source src={process.env.PUBLIC_URL + '/Animation.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Button
        onClick={onSkip}
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        Skip
      </Button>
    </div>
  );
};

export default FullScreenVideo;
